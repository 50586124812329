import {
	View,
	Text,
	Image,
	Linking,
	ScrollView,
	Alert,
	TouchableOpacity,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import tw from '../../../lib/tailwind';
import dateFormat from 'dateformat';
import {Avatar, Icon} from 'react-native-elements';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';

import * as Location from 'expo-location';
import {MaterialIcons} from '@expo/vector-icons';

import multiavatar from '@multiavatar/multiavatar'
import { SvgXml } from 'react-native-svg';

export default function BringerPackageDetail({navigation, route}) {
	const params = route.params;

	const packageData = params.data;

	const token = useSelector(selectToken);

	const [ownLocation, setOwnLocation] = useState(null);

	const avatar = multiavatar(packageData?.sender?.user_email);

	useEffect(() => {
		let interval = setInterval(() => {
			getOwnLocation();
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	async function getOwnLocation() {
		let location = await Location.getLastKnownPositionAsync();

		if (location) {
			setOwnLocation(location);
		}

		let currentLocation = await Location.getCurrentPositionAsync({
			accuracy: Location.Accuracy.Highest,
		});

		if (currentLocation) {
			setOwnLocation(currentLocation);
		}
	}

	async function handOverPackage() {
		if (
			parseInt(
				getDistance(ownLocation.coords, {
					latitude: packageData.originAddress.latitude,
					longitude: packageData.originAddress.longitude,
				})
			) > 100
		) {
			Alert.alert('Fehler', 'Du bist nicht in der Nähe des Abholortes.');
			return;
		}

		navigation.push('confirmHandOver', {
			requestID: params.data.data.accepted_request_id,
			action: 'recieve',
			type: 'bringer_recieved_from_sender',
			shipmentID: params.data.data.id,
		});
	}

	function getDistance(origin, destination) {
		const lon1 = toRadian(origin.longitude);
		const lat1 = toRadian(origin.latitude);
		const lon2 = toRadian(destination.longitude);
		const lat2 = toRadian(destination.latitude);

		const deltaLat = lat2 - lat1;
		const deltaLon = lon2 - lon1;

		const a =
			Math.pow(Math.sin(deltaLat / 2), 2) +
			Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
		const c = 2 * Math.asin(Math.sqrt(a));
		const EARTH_RADIUS = 6371;
		return c * EARTH_RADIUS * 1000; // convert to meters
	}

	function toRadian(degree) {
		return (degree * Math.PI) / 180;
	}

	async function deleteRequest() {
		try {
			let res = await axios.delete(process.env.APIURL + 'shipment/request', {
				data: {id: params.data.data.accepted_request_id},
				headers: {'Authorization': `Bearer ${token}`},
			});

			if (res.data) {
				navigation.goBack();
				Alert.alert('Erfolgreich', 'Die Anfrage wurde erfolgreich gelöscht.');
			}
		} catch (error) {
			Alert.alert(
				'Fehler',
				'Beim Löschen der Anfrage ist ein Fehler aufgetreten.'
			);
			console.log(error);
		}
	}

	return (
		<ScrollView>
			<View style={tw.style('p-6 flex-1 flex justify-between')}>
				<View style={tw.style('w-full mb-8 flex flex-row')}>
					{packageData.sender?.avatarSrc !== '' ?<Avatar
						rounded
						size={84}
						source={{
							uri:
								packageData.sender?.avatarSrc
						}}
					/>: 
					<div style={{width: 84, height:84}} dangerouslySetInnerHTML={{__html: avatar}} />}
					<View style={tw.style('ml-4 flex flex-col')}>
						<Text style={tw.style('text-accent text-base')}>U-Send</Text>
						<Text style={tw.style('text-black text-2xl')}>
							{packageData.sender?.first_name} {packageData.sender?.last_name}
						</Text>
						<Text
							style={tw.style('text-thin text-base underline')}
							onPress={() => {
								let phoneNumber = packageData.sender?.phone;
								if (Platform.OS !== 'android') {
									phoneNumber = `telprompt:${packageData.sender?.phone}`;
								} else {
									phoneNumber = `tel:${packageData.sender?.phone}`;
								}
								Linking.canOpenURL(phoneNumber)
									.then((supported) => {
										if (!supported) {
											Alert.alert(
												'Telefonnummer ist falsch',
												'Die Telefonnummer ist falsch oder wird nicht unterstützt'
											);
										} else {
											return Linking.openURL(phoneNumber);
										}
									})
									.catch((err) => console.log(err));
							}}
						>
							{packageData.sender?.phone}
						</Text>
					</View>
				</View>
				<View
					style={tw.style(
						'bg-white flex mb-8 flex-row rounded-xl shadow-md w-full overflow-hidden'
					)}
				>
					<Image
						source={{
							uri:
								packageData.data.imageSrc ||
								'https://images.unsplash.com/photo-1577705998148-6da4f3963bc8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
						}}
						style={[
							tw.style('min-h-[150px] h-full w-1/3 rounded-lg'),
							{aspectRatio: 1},
						]}
					/>
					<View style={tw.style('w-2/3 p-3')}>
						<View style={tw.style('flex flex-row justify-between')}>
							<Text style={tw.style('font-bold text-base')}>Preis:</Text>
							<Text style={tw.style('font-bold text-accent text-3xl')}>
								{(packageData.data.price / 100).toFixed(2).replace('.', ',') +
									' €'}
							</Text>
						</View>
						<View
							style={tw.style(
								'flex flex-row justify-between py-3 border-b border-gray-200'
							)}
						>
							<Text style={tw.style('font-bold text-base')}>
								Paket:
								{' "' +
									packageData.data.name +
									'" (' +
									packageData.category?.name +
									')'}
							</Text>
						</View>
						{packageData.data.length != 9999 && (
							<View
								style={tw.style('flex flex-row justify-between py-3 flex-wrap')}
							>
								<Text style={tw.style('text-base')}>Größe:</Text>
								<Text style={tw.style('font-bold text-accent')}>
									<Text style={tw.style('font-bold text-base')}>
										{'L: ' +
											packageData.data.length +
											'cm / B: ' +
											packageData.data.width +
											'cm / H: ' +
											packageData.data.height +
											'cm'}
									</Text>
								</Text>
							</View>
						)}
					</View>
				</View>
				<View style={tw.style('mb-8')}>
					<View>
						<View style={tw.style('pb-1 border-b border-gray-200')}>
							<Text style={tw.style('text-accent text-xl')}>Start</Text>
						</View>
						<View
							style={tw.style(
								'flex flex-row justify-between py-.5 border-b border-gray-200'
							)}
						>
							<Text style={tw.style('text-base font-light')}>Adresse</Text>
							<Text
								style={tw.style('text-base font-light max-w-2/3 text-right')}
							>
								{packageData.originAddress?.longString}
							</Text>
						</View>
						<View
							style={tw.style(
								'flex flex-row justify-between py-.5 border-b border-gray-200'
							)}
						>
							<Text style={tw.style('text-base font-light')}>Abfahrt</Text>
							<Text style={tw.style('text-base font-light')}>
								{dateFormat(
									new Date().setTime(packageData.data.date),
									'dd.mm.yyyy'
								)}
							</Text>
						</View>
						<View style={tw.style('pb-1 pt-4 border-b border-gray-200')}>
							<Text style={tw.style('text-primary text-xl')}>Ziel</Text>
						</View>
						<View
							style={tw.style(
								'flex flex-row justify-between py-.5 border-b border-gray-200'
							)}
						>
							<Text style={tw.style('text-base font-light')}>Empfänger</Text>
							<Text
								style={tw.style('text-base font-light max-w-2/3 text-right')}
							>
								{packageData.data.recipient}
							</Text>
						</View>
						<View
							style={tw.style(
								'flex flex-row justify-between py-.5 border-b border-gray-200'
							)}
						>
							<Text style={tw.style('text-base font-light')}>Adresse</Text>
							<Text
								style={tw.style('text-base font-light max-w-2/3 text-right')}
							>
								{packageData.destinationAddress?.longString}
							</Text>
						</View>
					</View>
				</View>
				<View style={tw.style('flex flex-row justify-between')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => handOverPackage()}
						disabled={true}
						label="Paket annehmen"
					/>
				</View>
				<View>
					<TouchableOpacity
						style={tw.style('flex flex-row justify-center mt-12')}
						onPress={() => {
							Alert.alert(
								'Abbrechen',
								'Bist du dir sicher das du diese Fahrt abbrechen möchtest?',
								[
									{
										text: 'Fahrt abbrechen',
										style: 'destructive',
										onPress: () => deleteRequest(),
									},
									{text: 'Zurück', style: 'cancel'},
								],
								{cancelable: true}
							);
						}}
					>
						<View
							style={tw.style('ml-4 flex flex-row justify-center items-center')}
						>
							<MaterialIcons
								name="block"
								size={24}
								color={tw.color('red-400')}
							/>
							<Text style={tw.style('text-lg ml-3 font-semibold text-red-500')}>
								Fahrt abbrechen
							</Text>
						</View>
					</TouchableOpacity>
				</View>
			</View>
		</ScrollView>
	);
}
