import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Text, ScrollView, Alert} from 'react-native';
import {Icon, FAB} from 'react-native-elements';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectToken} from '../../../slices/userSlice';
import Accordion from '../../../uiKit/accordion';
import {MaterialIcons} from '@expo/vector-icons';

export default function UBring({navigation}) {
	const token = useSelector(selectToken);

	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		updateRoutes();
	}, [token]);

	function updateRoutes() {
		axios
			.get(process.env.APIURL + 'routes', {
				headers: {'Authorization': `Bearer ${token}`},
			})
			.then((res) => {
				if (res?.data?.data) {
					setRoutes(res.data.data);
				}
			})
			.catch((e) => console.log(e));
	}

	function addRoute() {}

	return (
		<View style={tw.style('flex-1')}>
			<ScrollView style={tw.style('relative')}>
				<View style={tw.style('flex-1')}>
					{routes.length > 0 ? (
						routes
							.filter((data) => {
								return data.data.recurring == 1;
							})
							.map((data, id) => (
								<Accordion
									title={
										data.originAddress.city +
										'   ->   ' +
										data.destinationAddress.city
									}
									key={id}
									onClickPackages={() =>
										navigation.push('showPackages', {
											data: data,
										})
									}
									onEdit={() =>
										navigation.push('addRoute', {
											editingMode: true,
											data: data,
											destinationLocation: data.destinationAddress,
											originLocation: data.originAddress,
										})
									}
									onDelete={() => {
										axios
											.delete(
												process.env.APIURL + 'route/' + data.data.id,

												{
													data: {
														id: parseInt(data.data.id),
													},
													headers: {'Authorization': `Bearer ${token}`},
												}
											)
											.then((res) => {
												if (res.data) {
													updateRoutes();
													Alert.alert(
														'Route gelöscht',
														'Deine Route wurde erfolgreich gelöscht'
													);
												}
											})
											.catch((e) => console.log(e.response.data));
									}}
									{...data}
								/>
							))
					) : (
						<View style={tw.style('flex-1 flex justify-center items-center')}>
							<Text>Noch hast du keine Routen gespeichert</Text>
							<View style={tw.style('absolute bottom-20 right-8')}>
								<Text style={tw.style('mb-2 text-gray-400 font-bold')}>
									Jetzt eine Route anlegen
								</Text>
								<MaterialIcons
									name="undo"
									containerStyle={{
										transform: [{rotate: '-90deg'}],
									}}
									size={48}
									color={tw.color('gray-400')}
								/>
							</View>
						</View>
					)}
				</View>
			</ScrollView>
			<FAB
				icon={<MaterialIcons name="add" color={tw.color('white')} size={24} />}
				color={tw.color('primary')}
				containerStyle={tw.style('absolute right-2 bottom-2 shadow-lg')}
				placement="right"
				size="large"
				onPress={() => navigation.push('addRoute')}
			/>
		</View>
	);
}
