import {
	View,
	Text,
	TouchableWithoutFeedback,
	ScrollView,
	KeyboardAvoidingView,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import {useSelector} from 'react-redux';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';
import {CheckBox, Icon} from 'react-native-elements';
import Input from '../../../uiKit/input';
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import uuid from 'uuid';
import axios from 'axios';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function SetDestination({navigation, route}) {
	const [buttonDeactivated, setButtonDeativated] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);

	const [useOwnAddress, setUseOwnAddress] = useState(false);
	const [useNewAddress, setUseNewAddress] = useState(true);

	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');

	const [locationError, setLocationError] = useState(false);
	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [emailError, setEmailError] = useState(false);

	const titleStyle = tw.style('font-semibold text-lg mb-10');

	const userInfo = useSelector(selectUserInfo);
	const token = useSelector(selectToken);

	const [location, setLocation] = useState(null);
	const [tempLocation, setTempLocation] = useState('');

	useEffect(() => {
		let params = route.params;

		if (params) {
			setLocation({
				streetName: params.location.street,
				houseNumber: params.location.number,
				postal: params.location.postal,
				city: params.location.city,
				lat: params.location.latitude,
				long: params.location.longitude,
				state: params.location.state,
				placeID: params.location.placeID,
				longString: params.location.longString,
			});
			setFirstname(params.reciever.firstname);
			setLastname(params.reciever.lastname);
			setTempLocation(params.location.longString);
			setEmail(params.reciever.email);

			setUseNewAddress(true);
			setUseOwnAddress(false);
		}
	}, []);

	useEffect(() => {
		let timeout = setTimeout(() => {
			getAddressByNameAndMail();
		}, 400);

		return () => {
			clearTimeout(timeout);
		};
	}, [email, firstname, lastname]);

	async function getAddressByNameAndMail() {
		if (location) {
			return;
		}

		try {
			const response = await axios.post(
				process.env.APIURL + 'user/getAdressForUser',
				{
					firstname: firstname,
					lastname: lastname,
					email: email,
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			);

			console.log(response.status, response.data);

			if (response.data.longString) {
				setLocation({
					streetName: response.data.street,
					houseNumber: response.data.number,
					postal: response.data.postal,
					city: response.data.city,
					lat: parseFloat(response.data.latitude),
					long: parseFloat(response.data.longitude),
					state: response.data.state,
					placeID: uuid.v4(),
					longString: response.data.longString,
				});
				setTempLocation(response.data.longString);

				setFirstname(response.data.firstname);
				setLastname(response.data.lastname);
			}
		} catch (error) {
			console.log('autocomplete error', error);
		}
	}

	function updateSelectedDestination(selector) {
		switch (selector) {
			case 'ownAddress':
				setLocation({
					streetName: userInfo.address.street,
					houseNumber: userInfo.address.number,
					postal: userInfo.address.postal,
					city: userInfo.address.city,
					lat: userInfo.address.latitude,
					long: userInfo.address.longitude,
					state: userInfo.address.state,
					placeID: userInfo.address.placeID,
					longString: userInfo.address.longString,
				});
				setFirstname(userInfo.firstname);
				setLastname(userInfo.lastname);
				setTempLocation(userInfo.address.longString);
				setEmail(userInfo.email);
				setUseOwnAddress(true);
				setUseNewAddress(false);
				break;

			case 'newAddress':
				setUseNewAddress(true);
				setUseOwnAddress(false);
				break;

			default:
				break;
		}
	}

	useEffect(() => {
		if (!location) {
			setButtonDeativated(true);
			return;
		}

		if (useOwnAddress) {
			setButtonDeativated(false);
		} else {
			setButtonDeativated(true);
			const {city, houseNumber, lat, long, postal, streetName, state} =
				location;

			if (
				!(city && houseNumber && lat && long && postal && streetName && state)
			) {
				setLocationError(true);
				setButtonDeativated(true);
				return;
			}

			let emailerror = !email
				.toString()
				.trim()
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);

			let firstnameerror = firstname.trim() == '';
			let lastnameerror = lastname.trim() == '';

			if (emailerror) {
				setEmailError('Bitte gib eine gültige E-Mail Adresse ein');
			} else {
				setEmailError(false);
			}

			if (firstnameerror) {
				setFirstnameError('Bitte gib deinen Vornamen ein');
			} else {
				setFirstnameError(false);
			}

			if (lastnameerror) {
				setLastnameError('Bitte gib deinen Nachnamen ein');
			} else {
				setLastnameError(false);
			}

			if (emailerror || firstnameerror || lastnameerror) {
				setButtonDeativated(true);
			} else {
				setButtonDeativated(false);
			}
		}
	}, [useOwnAddress, useNewAddress, location, firstname, lastname, email]);

	function saveDestination() {
		navigation.navigate({
			name: 'createPackage',
			params: {
				destination: {
					reciever: {
						gender: '',
						firstname: firstname,
						lastname: lastname,
						email: email,
					},
					location: {
						postal: location.postal,
						street: location.streetName,
						number: location.houseNumber,
						city: location.city,
						state: location.state,
						latitude: location.lat,
						longitude: location.long,
						placeID: location.placeID,
						shortString: location.streetName + ' ' + location.houseNumber,
						longString:
							location.streetName +
							' ' +
							location.houseNumber +
							', ' +
							location.postal +
							' ' +
							location.city +
							', Deutschland',
					},
					isInnitSet: false,
				},
			},
			merge: true,
		});
	}

	return (
		<KeyboardAwareScrollView
			style={tw.style('flex-1')}
			enableAutomaticScroll
			enableOnAndroid
			keyboardShouldPersistTaps="handled"
			nestedScrollEnabled={true}
		>
			<View style={tw.style('px-6 pt-6 flex-1 justify-between')}>
				<View style={tw.style('')}>
					<View style={tw.style('flex ')}>
						<View style={tw.style('flex-row justify-between items-center')}>
							<TouchableWithoutFeedback
								onPress={() => updateSelectedDestination('newAddress')}
							>
								<Text style={titleStyle}>Zieladresse</Text>
							</TouchableWithoutFeedback>
						</View>
						<GooglePlacesAutocomplete
							requestUrl={{
								useOnPlatform: 'web', // or "all"
							url: process.env.APIURL + 'route/google', // or any proxy server that hits https://maps.googleapis.com/maps/api
							headers: {
								Authorization: 'Bearer ' + token,
							}
							}}
							styles={{
								'textInput': tw.style(''),
								'textInputContainer': tw.style(
									'bg-white rounded-xl shadow-md py-4 px-5'
								),
								'listView': tw.style('mt-1 rounded-xl mb-2'),
								'row': tw.style('bg-white p-3 px-2 '),
								'separator': tw.style('bg-gray-300 h-.1'),
							}}
							placeholder={
								location
									? location.longString != ''
										? location.longString
										: 'Adresse'
									: 'Adresse'
							}
							textInputProps={{
								placeholderTextColor: tw.color('black'),
								returnKeyType: 'search',
								value: tempLocation,
								onChangeText: (event) => setTempLocation(event),
							}}
							nearbyPlacesAPI="GooglePlacesSearch"
							debounce={400}
							enablePoweredByContainer={false}
							query={{
								key: process.env.GOOGLE_API_KEY,
								language: 'de',
								components: 'country:de',
							}}
							minLength={2}
							fetchDetails={true}
							returnKeyType="Suchen"
							suppressDefaultStyles
							onFail={(error) => console.warn(error)}
							onPress={(data, details = null) => {
								setLocationError(false);
								var houseNumber = 0;
								var streetName = '';
								var postal = 0;
								var city = '';
								var state = '';

								details.address_components.map(({types, long_name}) => {
									switch (types[0]) {
										case 'street_number':
											houseNumber = long_name;
											break;

										case 'route':
											streetName = long_name;
											break;

										case 'administrative_area_level_1':
											state = long_name;
											break;

										case 'postal_code':
											postal = long_name;
											break;

										case 'locality':
											city = long_name;
											break;

										default:
											break;
									}
								});
								setTempLocation(
									streetName +
										' ' +
										houseNumber +
										', ' +
										postal +
										' ' +
										city +
										', Deutschland'
								);

								setLocation({
									streetName: streetName,
									houseNumber: houseNumber,
									postal: postal,
									city: city,
									lat: details.geometry.location.lat,
									long: details.geometry.location.lng,
									state: state,
									placeID: uuid.v4(),
								});
							}}
						/>
						<Text
							style={[
								tw.style(
									'mt-2 mb-4 text-sm ' +
										(locationError
											? 'text-red-500 font-bold'
											: 'text-gray-900 font-light')
								),
								{zIndex: 1},
							]}
						>
							Um eine Adresse zu nutzen, wähle einen der Vorschläge aus. Bitte
							Adresse mit Straße, Hausnummer und Ort eintragen
						</Text>
						<Input
							placeholder="Vorname"
							onChangeText={(event) => setFirstname(event)}
							value={firstname}
							error={firstnameError}
						/>
						<Input
							placeholder="Nachname"
							onChangeText={(event) => setLastname(event)}
							value={lastname}
							error={lastnameError}
						/>
						<Input
							placeholder="E-Mail"
							onChangeText={(event) => setEmail(event)}
							value={email}
							keyboardType="email-address"
							autoCapitalize="none"
							type={'email'}
							error={emailError}
						/>
					</View>
				</View>
				<View
					style={tw.style(
						'w-full flex flex-row justify-between mb-3 mt-10 z-1'
					)}
				>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => saveDestination()}
						label="Speichern"
						disabled={buttonDeactivated}
						loading={buttonLoading}
						showIcon={buttonLoading}
					/>
				</View>
			</View>
		</KeyboardAwareScrollView>
	);
}
