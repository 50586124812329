import {View, Text, FlatList, TouchableOpacity} from 'react-native';
import React from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import {Avatar, Icon} from 'react-native-elements';
import Ratings from '../../../uiKit/Ratings';
import {MaterialIcons} from '@expo/vector-icons';

import multiavatar from '@multiavatar/multiavatar'
import { SvgXml } from 'react-native-svg';

export default function ViewBringer({navigation, route}) {
	const packageData = route.params.packageData;

	return (
		<View style={tw.style('flex-1 p-6 flex justify-between')}>
			<Text style={tw.style('text-2xl font-semibold mb-4 text-gray-600')}>
				Wähle einen Bringer aus
			</Text>
			<FlatList
				data={packageData.requests}
				keyExtractor={(item) => item.id}
				showsVerticalScrollIndicator={false}
				renderItem={({item, index}) => {

					const avatar = multiavatar(item?.bringer?.user_email);
					return (
						<TouchableOpacity
							onPress={() =>
								navigation.navigate('acceptBringer', {
									bringerData: item,
									packageData,
								})
							}
							activeOpacity={0.8}
						>
							<View
								style={tw.style(
									'flex-row items-center justify-between bg-white rounded-lg p-4 mb-4'
								)}
							>
								<View style={tw.style(' flex flex-row')}>
									{item.bringer.avatarSrc !== null ? <Avatar
										rounded
										size={64}
										source={{
											uri:
												item.bringer.avatarSrc
										}}
									/>:
										<div style={{width: 64, height:64}} dangerouslySetInnerHTML={{__html: avatar}} />
									}
									<View style={tw.style('ml-4 flex flex-col justify-center')}>
										<Text style={tw.style('text-lg font-bold')}>
											{item.bringer.first_name + ' ' + item.bringer.last_name}
										</Text>
										<Ratings userID={item.bringer_id} />
									</View>
								</View>
								<View style={tw.style('flex-row items-center')}>
									<MaterialIcons name="chevron-right" size={24} />
								</View>
							</View>
						</TouchableOpacity>
					);
				}}
			/>
			<View style={tw.style('flex flex-row justify-between')}>
				<BackButton onPress={() => navigation.goBack()} />
			</View>
		</View>
	);
}
