import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TouchableWithoutFeedback,
	Touchable,
	TouchableHighlight,
	Alert,
	ScrollView,
	Platform,
} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import MapView from 'react-native-maps';
import axios from 'axios';
import Input from '../../../uiKit/input';
import {Icon} from 'react-native-elements';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import MapViewDirections from 'react-native-maps-directions';
import dateFormat from 'dateformat';

import DateTimePickerModal from 'react-native-modal-datetime-picker';

import MarkerBringer from '../../../assets/images/icons/map/Bringer_MapIcon.png';
import MarkerHome from '../../../assets/images/icons/map/Home_MapIcon.png';
import {MaterialIcons} from '@expo/vector-icons';

export default function AddRoute({navigation, route}) {
	const [originLocation, setOriginLocation] = useState(null);
	const [destinationLocation, setDestinationLocation] = useState(null);

	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const [recurring, setRecurring] = useState(false);

	const [waypoints, setWaypoints] = useState(null);

	const [showDatePicker, setShowDatePicker] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [showTimePicker, setShowTimePicker] = useState(false);

	const [startTime, setStartTime] = useState(new Date());

	const calenderRef = useRef();
	const timeRef = useRef();

	const token = useSelector(selectToken);

	const params = route.params;

	const editingMode =
		typeof params?.editingMode !== 'undefined' ? params.editingMode : false;

	const mapRef = useRef();

	useEffect(() => {
		if (params?.originLocation) {
			setOriginLocation({
				...params.originLocation,
				latitude: parseFloat(params.originLocation.latitude),
				longitude: parseFloat(params.originLocation.longitude),
			});
		}

		if (params?.destinationLocation) {
			setDestinationLocation({
				...params.destinationLocation,
				latitude: parseFloat(params.destinationLocation.latitude),
				longitude: parseFloat(params.destinationLocation.longitude),
			});
		}

		if (params?.data?.data?.recurring) {
			setRecurring(parseInt(params.data.data.recurring));
		}

		if (params?.data?.data?.date) {
			setStartDate(
				new Date(new Date().setTime(params.data.data.date)) || new Date()
			);
		}

		if (params?.data?.data?.time) {
			setStartTime(
				new Date(new Date().setTime(params.data.data.time)) || new Date()
			);
		}
	}, [params]);

	useEffect(() => {
		if (!originLocation || !destinationLocation || Platform.OS == 'web') return;
		mapRef.current.fitToSuppliedMarkers(['origin', 'destination'], {
			edgePadding: {top: 75, left: 75, bottom: 75, right: 75},
		});
	}, [originLocation, destinationLocation]);

	useEffect(() => {
		if (originLocation && destinationLocation && waypoints && !editingMode) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}

		if (!editingMode) {
			return;
		}

		setButtonDisabled(
			!(
				!(
					params.destinationLocation.latitude ==
						params.data.destinationAddress.latitude &&
					params.destinationLocation.longitude ==
						params.data.destinationAddress.longitude
				) ||
				!(
					params.originLocation.latitude ==
						params.data.originAddress.latitude &&
					params.originLocation.longitude == params.data.originAddress.longitude
				) ||
				params.data.data.recurring != recurring ||
				dateFormat(new Date().setTime(params.data.data.time), 'HH:MM') !=
					dateFormat(startTime, 'HH:MM')
			)
		);
	}, [
		originLocation,
		destinationLocation,
		waypoints,
		params,
		recurring,
		startDate,
		startTime,
	]);

	function saveRoute() {
		setButtonLoading(true);
		axios
			.put(
				process.env.APIURL + 'route',

				{
					origin: originLocation,
					destination: destinationLocation,
					directions: JSON.stringify(waypoints),
					recurring: recurring,
					date: startDate.getTime(),
					time: startTime.getTime(),
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				if (res.data) {
					setButtonLoading(false);

					navigation.replace('showPackages', {
						data: {
							destinationAddress: {
								latitude: destinationLocation.latitude,
								longitude: destinationLocation.longitude,
							},
							originAddress: {
								latitude: originLocation.latitude,
								longitude: originLocation.longitude,
							},
							id: res.data.data.id,
						},
					});
				}
			})
			.catch((e) => {
				console.log(e.response.data);
				Alert.alert(
					'Fehler',
					e.response.message || 'Es ist ein unbekannter Fehler aufgetreten'
				);
			});
	}

	function updateRoute() {
		setButtonLoading(true);
		axios
			.post(
				process.env.APIURL + 'route',

				{
					id: parseInt(params.data.data.id),
					origin:
						params.originLocation.latitude ==
							params.data.originAddress.latitude &&
						params.originLocation.longitude ==
							params.data.originAddress.longitude
							? undefined
							: originLocation,
					destination:
						params.destinationLocation.latitude ==
							params.data.destinationAddress.latitude &&
						params.destinationLocation.longitude ==
							params.data.destinationAddress.longitude
							? undefined
							: destinationLocation,

					recurring: recurring == true,
					directions: JSON.stringify(waypoints),
					time: startTime.getTime(),
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				if (res.data) {
					setButtonLoading(false);
					//console.log(res.data);
					navigation.replace('route', {
						merge: true,
					});

					Alert.alert(
						'Route erfolgreich aktualisiert',
						'Deine Route wurde erfolgreich aktualisiert',
						[
							{
								text: 'OK',
								onPress: () => {},
							},
						],
						{cancelable: true}
					);
				}
			})
			.catch((e) => {
				setButtonLoading(false);
				console.log(e.response.data);
			});
	}

	return (
		<ScrollView contentContainerStyle={tw.style('')} nestedScrollEnabled>
			{Platform.OS != 'web' && (
				<>
					<DateTimePickerModal
						isVisible={showDatePicker}
						onConfirm={(date) => {
							setStartDate(date);
							setShowDatePicker(false);
						}}
						onCancel={() => {
							setShowDatePicker(false);
						}}
						isDarkModeEnabled={false}
						textColor={tw.color('black')}
						confirmTextIOS="Bestätigen"
						cancelTextIOS="Abbrechen"
						date={typeof startDate.getT ? startDate : new Date()}
						themeVariant="light"
						minimumDate={new Date()}
						locale="de-DE"
						maximumDate={
							new Date(new Date().setFullYear(new Date().getFullYear() + 1))
						}
					/>
					<DateTimePickerModal
						isVisible={showTimePicker}
						onConfirm={(date) => {
							setStartTime(date);
							setShowTimePicker(false);
						}}
						onCancel={() => {
							setShowTimePicker(false);
						}}
						mode="time"
						isDarkModeEnabled={false}
						textColor={tw.color('black')}
						confirmTextIOS="Bestätigen"
						cancelTextIOS="Abbrechen"
						date={typeof startTime.getT ? startTime : new Date()}
						themeVariant="light"
						locale="de-DE"
					/>
				</>
			)}

			<TouchableWithoutFeedback style={tw.style('')}>
				<View>
					<Text
						style={tw.style(
							'text-2xl font-bold text-gray-700 text-center mt-7 mb-10'
						)}
					>
						Meine Route
					</Text>
					<View style={tw.style('mx-6')}>
						<View
							style={tw.style(
								'flex flex-row justify-between items-center mb-4'
							)}
						>
							<Text style={tw.style('font-semibold')}>Von</Text>
							<TouchableHighlight
								onPress={() => navigation.push('setOrigin')}
								style={tw.style('w-2/3 flex flex-row justify-end rounded-xl')}
							>
								<Input
									placeholder="Startadresse"
									disabled
									width="1/1 m-0"
									value={originLocation?.longString}
									onPress={() => navigation.push('setOrigin')}
								/>
							</TouchableHighlight>
						</View>
						<View
							style={tw.style('flex flex-row justify-between items-center')}
						>
							<Text style={tw.style('font-semibold')}>Bis</Text>

							<TouchableHighlight
								onPress={() => navigation.push('setDestination')}
								style={tw.style('w-2/3 flex flex-row justify-end rounded-xl')}
							>
								<Input
									placeholder="Zieladresse"
									width="1/1 m-0"
									disabled
									value={destinationLocation?.longString}
								/>
							</TouchableHighlight>
						</View>
					</View>
					<View style={tw.style('w-4/5 bg-gray-300 h-.25 my-3 self-center')} />
					<View style={tw.style('w-full flex flex-row justify-evenly mb-3')}>
						
							<TouchableOpacity
								style={tw.style('flex flex-row items-center justify-center')}
								onPress={() => {
									let dateInput = timeRef.current;
									if (typeof dateInput.showPicker === 'function') {
									dateInput.showPicker();
									} else {
									// Otherwise, try to simulate a click
									dateInput.focus();
									dateInput.click();
									}
								}}
							>
								<MaterialIcons
									name="schedule"
									size={24}
									style={tw.style('mr-2.5')}
									color={tw.color('accent')}
								/>
								<View style={tw.style('flex')}>
									<Text style={tw.style('ml-1 text-base font-medium')}>
										Abfahrt
									</Text>
									<Text style={tw.style('ml-1 font-medium')}>
										{dateFormat(startTime, 'HH:MM')}
									</Text>
									<input type='time' id='time'
									style={{display: 'none'}}
									ref={timeRef}
								 onChange={
									(e) => {
										setStartTime(new Date().setHours(e.target.value.split(':')[0], e.target.value.split(':')[1]));
									}
								}
								value={dateFormat(startTime, 'HH:MM')}

								/>
								</View>
							</TouchableOpacity>

						{!editingMode && (
							<TouchableOpacity
								style={tw.style('flex flex-row items-center')}
								onPress={() => {
									let dateInput = calenderRef.current;
									if (typeof dateInput.showPicker === 'function') {
									dateInput.showPicker();
									} else {
									// Otherwise, try to simulate a click
									dateInput.focus();
									dateInput.click();
									}
								}}
							>
								<MaterialIcons
									name="calendar-today"
									size={24}
									style={tw.style('mr-2.5')}
									color={tw.color('accent')}
								/>
								<View style={tw.style('flex')}>
									<Text style={tw.style('ml-1 text-base font-medium')}>
										Datum
									</Text>
									<Text style={tw.style('ml-1 font-medium')}>
										{dateFormat(startDate, 'dd.mm.yyyy')}
									</Text>
								</View>
								<input type='date' id='calendar'
									style={{display: 'none'}}
									ref={calenderRef}
									min={dateFormat(new Date(), 'yyyy-mm-dd')}
								 onChange={
									(e) => {
										setStartDate(new Date(e.target.value));
									}
								} value={dateFormat(startDate, 'yyyy-mm-dd')}/>
							</TouchableOpacity>
						)}
						<TouchableOpacity
							style={tw.style('flex flex-row items-center')}
							onPress={() => {
								setRecurring(!recurring);
							}}
						>
							<MaterialIcons
								name={recurring ? 'check-box' : 'check-box-outline-blank'}
								size={24}
								style={tw.style('mr-2.5')}
								color={tw.color('accent')}
							/>
							<View style={tw.style('flex')}>
								<Text style={tw.style('ml-1 text-base font-medium')}>
									Wiederholend?
								</Text>
								<Text style={tw.style('ml-1 font-medium')}>
									{recurring ? 'Ja' : 'Nein'}
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<MapView
						initialRegion={{
							latitude: 51.37052,
							longitude: 10.52277,
							latitudeDelta: 5,
							longitudeDelta: 5,
						}}
						loadingIndicatorColor={tw.color('primary')}
						loadingBackgroundColor={tw.color('gray-300')}
						mapType="standard"
						style={[tw.style('w-full'), {height: 250}]}
						ref={mapRef}
					>
						{originLocation && destinationLocation && (
							<MapViewDirections
								origin={{
									latitude: originLocation.latitude,
									longitude: originLocation.longitude,
								}}
								destination={{
									latitude: destinationLocation.latitude,
									longitude: destinationLocation.longitude,
								}}
								apikey={process.env.GOOGLE_API_KEY}
								strokeColor={tw.color('accent')}
								strokeWidth={2}
								mode="DRIVING"
								onReady={(result) => setWaypoints(result.coordinates)}
							/>
						)}

						{Platform.OS == 'web' && originLocation && (
							<MapView.Marker
								icon={Platform.OS === 'web' && MarkerHome}
								coordinate={{
									latitude: originLocation.latitude,
									longitude: originLocation.longitude,
								}}
								tracksViewChanges={false}
								identifier="origin"
								image={MarkerHome}
							>
								{/* <Image source={MarkerHome} resizeMode="contain" /> */}
							</MapView.Marker>
						)}
						{Platform.OS == 'web' && destinationLocation && (
							<MapView.Marker
								coordinate={{
									latitude: destinationLocation.latitude,
									longitude: destinationLocation.longitude,
								}}
								identifier="destination"
								tracksViewChanges={false}
								icon={Platform.OS === 'web' && MarkerBringer}
								image={MarkerBringer}
							>
								{/* <Image source={MarkerBringer} resizeMode="contain" /> */}
							</MapView.Marker>
						)}
					</MapView>
					<View style={tw.style('flex flex-row my-4 px-6')}>
						<BackButton
							onPress={() =>
								navigation.replace('route', {
									merge: true,
								})
							}
						/>
						<NextButton
							label={editingMode ? 'Aktualisieren' : 'Speichen'}
							showIcon
							disabled={buttonDisabled}
							loading={buttonLoading}
							onPress={() => (editingMode ? updateRoute() : saveRoute())}
						/>
					</View>
				</View>
			</TouchableWithoutFeedback>
		</ScrollView>
	);
}
