import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
	View,
	Text,
	Platform,
	Dimensions,
	KeyboardAvoidingView,
	ScrollView,
	TextInput,
	Alert,
	TouchableOpacity,
} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {
	selectRegisterUserInfo,
	setUserID,
} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';
import {v4 as uuidv4} from 'uuid';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import * as WebBrowser from 'expo-web-browser';
import {MaterialIcons} from '@expo/vector-icons';

import RenderHtml from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';

export default function LegalForm({navigation}) {
	const [disableButton, setDisableButton] = useState(true);
	const [checkLegal, setCheckLegal] = useState(false);

	const [agb, setAgb] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const newUserInfo = useSelector(selectRegisterUserInfo);
	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	const dispatch = useDispatch();

	useEffect(() => {
		if (checkLegal) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	}, [checkLegal]);

	useEffect(() => {
		getAGB();
	}
	, []);

	async function getAGB(){
		try {
			let res = await axios.get(process.env.APIURL + 'user/agb');

			if (res.data) {
				setAgb(res.data.data);
			}
		} catch (error) {
			console.log(error.response);
			Alert.alert(
				'Fehler',
				'Beim laden der AGB ist ein Fehler aufgetreten.'
			);
		}
	} 

	async function registerUser() {
		setIsLoading(true);

		try {
			let res = await axios.post(process.env.APIURL + 'register', {
				'username': newUserInfo.username,
				'firstname': newUserInfo.firstname,
				'lastname': newUserInfo.lastname,
				'companyname': newUserInfo.isCompany ? newUserInfo.companyName : '',
				'address': {
					'city': newUserInfo.addressInformation.city,
					'state': newUserInfo.addressInformation.state,
					'latitude': newUserInfo.addressInformation.lat,
					'longitude': newUserInfo.addressInformation.long,
					'shortString':
						newUserInfo.addressInformation.streetName +
						' ' +
						newUserInfo.addressInformation.houseNumber,
					'longString':
						newUserInfo.addressInformation.streetName +
						' ' +
						newUserInfo.addressInformation.houseNumber +
						', ' +
						newUserInfo.addressInformation.postal +
						' ' +
						newUserInfo.addressInformation.city +
						', Deutschland',
					'number': newUserInfo.addressInformation.houseNumber,
					'street': newUserInfo.addressInformation.streetName,
					'postal': newUserInfo.addressInformation.postal,
					'placeID': uuidv4(),
				},
				'email': newUserInfo.email,
				'telephone': newUserInfo.phoneNumber,
				'password': newUserInfo.password,
				'legal': true,
			});

			if (res.data) {
				console.log(res.data);
				dispatch(setUserID(res.data.data.id));
				setIsLoading(false);
				navigation.reset({
					index: 0,
					routes: [{name: 'validateCode'}],
				});
			}
		} catch (error) {
			console.log(error.response);
			Alert.alert(
				'Fehler',
				'Beim registrieren ist ein Fehler aufgetreten. Die E-Mail Adresse wurde bereits registriert.'
			);
			setIsLoading(false);
		}
	}

	const titleStyle = tw.style('text-lg font-semibold mb-4');
	const paragraphStyle = tw.style('mb-3');

	const { width } = useWindowDimensions();

	return (
		<View
			style={tw.style(
				'flex-1 w-full h-full flex justify-between items-center px-6'
			)}
		>
			<View style={tw.style('w-full')}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Zustimmung AGB und Einwilligung zur Datenerfassung
				</Text>
			</View>
			<ScrollView bounces={false} style={tw.style('flex-1 w-full')}>
				<View style={tw.style('flex-1')}>
					
					{agb && <RenderHtml contentWidth={width} source={{html: agb}}  />}

					<CheckBox
						checked={checkLegal}
						onPress={() => setCheckLegal(!checkLegal)}
						containerStyle={tw.style(
							'bg-background shadow-none border-0 bg-opacity-0'
						)}
						
						checkedIcon={
							<MaterialIcons
								name="radio-button-checked"
								color={tw.color('primary')}
								style={tw.style('mr-2.5')}
								size={24}
							/>
						}
						uncheckedIcon={
							<MaterialIcons
								name="radio-button-unchecked"
								color={tw.color('primary')}
								style={tw.style('mr-2.5')}
								size={24}
							/>
						}
						title={
							'Ich habe die AGB gelesen und aktzeptiere die Verarbeitung meiner Daten nach Datenschutzerklärung.'
						}
					/>
				</View>
			</ScrollView>
			<View>
				<View style={tw.style('flex flex-row justify-center w-full mb-6')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => registerUser()}
						label="Registrieren"
						showIcon
						disabled={disableButton}
						loading={isLoading}
					/>
				</View>
				{Platform.OS === 'ios' && <KeyboardSpacer />}
			</View>
		</View>
	);
}
