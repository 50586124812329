import {
	View,
	Text,
	ScrollView,
	TouchableWithoutFeedback,
	Keyboard,
	TouchableOpacity,
	Image,
	Alert,
	Platform,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
	selectToken,
	selectUserInfo,
	setUserInformation,
} from '../../../slices/userSlice';
import Input from '../../../uiKit/input';
import {SafeAreaView} from 'react-native-safe-area-context';
import tw from '../../../lib/tailwind';
import axios from 'axios';
import {BottomSheet, CheckBox, Icon, ListItem} from 'react-native-elements';

// Cars

import Bike from './../../../assets/images/icons/cars/Ubring_Auto_Motorrad.png';
import Klein from '../../../assets/images/icons/cars/Ubring_Auto_Kleinwagen.png';
import Kompakt from './../../../assets/images/icons/cars/Ubring_Auto_Golf.png';
import Limo from '../../../assets/images/icons/cars/Ubring_Auto_Limousine.png';
import Kombi from '../../../assets/images/icons/cars/Ubring_Auto_Kombi.png';
import SUV from '../../../assets/images/icons/cars/Ubring_Auto_SUV.png';
import Van from '../../../assets/images/icons/cars/Ubring_Auto_Van.png';
import Kasten from '../../../assets/images/icons/cars/Ubring_Auto_Transporter.png';
import LKW from '../../../assets/images/icons/cars/Ubring_Auto_KleinLaster.png';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import Trash from '../../../assets/images/icons/trash-icon.png';
import Camera from '../../../assets/images/icons/camera-icon.png';

import Placeholder from '../../../assets/images/icons/image_placeholder.png';

import * as ImagePicker from 'expo-image-picker';

import * as FileSystem from 'expo-file-system';
import * as SecureStore from 'expo-secure-store';

import {useDispatch} from 'react-redux';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MaterialIcons} from '@expo/vector-icons';

export default function ViewBringerData({navigation}) {
	function sub(base, exponent) {
		return (
			<View style={{flexDirection: 'row'}}>
				<View style={{alignItems: 'flex-end'}}>
					<Text style={tw.style('')}>{base}</Text>
				</View>
				<View style={{alignItems: 'flex-start'}}>
					<Text style={tw.style('text-xs')}>{exponent}</Text>
				</View>
			</View>
		);
	}

	const userInfo = useSelector(selectUserInfo);
	const token = useSelector(selectToken);

	const dispatch = useDispatch();

	const [art, setArt] = useState(userInfo.extended.art);
	const [bikerack, setBikeRack] = useState(userInfo.extended.bikerack);
	const [licenseplate, setLicensePlate] = useState(
		userInfo.extended.licenseplate
	);
	const [roofrack, setRoofRack] = useState(userInfo.extended.roofrack);
	const [towhook, setTowHook] = useState(userInfo.extended.towhook);
	const [volume, setVolume] = useState(userInfo.extended.volume);

	const [buttonLoading, setButtonLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const [disableCheckbox, setDisableCheckbox] = useState(false);

	const [isVisible, setIsVisible] = useState(false);
	const [list, setList] = useState([
		{
			allowance: '0',
			createdAt: '2022-06-17 14:50:21',
			description: '',
			icon_url: '',
			id: 'loadingID',
			modifiedAt: '2022-06-17 14:51:07',
			name: 'Wird geladen...',
			volume: '0',
		},
	]);
	const [selectedCar, setSelectedCar] = useState({
		allowance: '0',
		createdAt: '2022-06-17 14:50:21',
		description: '',
		icon_url: '',
		id: 'selectedID',
		modifiedAt: '2022-06-17 14:51:07',
		name: 'Fahrzeugtyp wählen',
		volume: '0',
		vehicle_accessories: '',
	});

	const [imageUri, setImageUri] = useState(null);
	const [imageType, setImageType] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [imageViewSize, setImageViewHeight] = useState(0);

	const [imageRes, setImageRes] = useState(null);

	const [imageViewWidth, setImageViewWidth] = useState(0);

	function getIcon(icon) {
		switch (icon) {
			case 'motorrad':
				return Bike;

			case 'klein':
				return Klein;

			case 'kompakt':
				return Kompakt;

			case 'limo':
				return Limo;

			case 'kombi':
				return Kombi;

			case 'suv':
				return SUV;

			case 'van':
				return Van;

			case 'kasten':
				return Kasten;

			case 'lkw':
				return LKW;

			default:
				break;
		}
	}

	useEffect(() => {
		getAllVehicles();
	}, []);

	async function getAllVehicles() {
		axios
			.get(
				process.env.APIURL + 'user/validate/getAllVehicles',

				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				setList(res.data);
				setSelectedCar(
					res.data.find((car) => car.name == userInfo.extended.art ) || selectedCar
				);

				console.log(res.data.find((car) => car.name == userInfo.extended.art));
			})
			.catch((e) => console.log(e.response.message));
	}

	useEffect(() => {
		if (
			art != userInfo.extended.art ||
			bikerack != userInfo.extended.bikerack ||
			licenseplate != userInfo.extended.licenseplate ||
			roofrack != userInfo.extended.roofrack ||
			towhook != userInfo.extended.towhook ||
			volume != userInfo.extended.volume ||
			imageUri
		) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [art, bikerack, licenseplate, roofrack, towhook, volume, imageUri]);

	useEffect(() => {
		if (selectedCar.name == 'Motorrad') {
			setRoofRack(false);
			setBikeRack(false);
			setDisableCheckbox(true);
		} else {
			setDisableCheckbox(false);
		}
	}, [selectedCar]);

	async function handleUploadImage(type) {
		let result;

		try {
			if (type === 'camera') {
				await ImagePicker.requestCameraPermissionsAsync();
				result = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [43, 27],
					quality: 1,
				});
			} else {
				result = await ImagePicker.launchImageLibraryAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [43, 27],
					quality: 1,
				});
			}
		} catch (error) {
			console.log(error);
		}

		if (!result.canceled) {
			setImageType(result.assets[0].type);
			setImageName(result.assets[0].fileName);

			setImageRes(result);

			let ratio = imageViewWidth / result.assets[0].width;
			let height = result.assets[0].height * ratio;

			setImageViewHeight(height);

			setImageUri(result.assets[0].uri);

			setButtonDisabled(false);
		}

		console.log(result);
	}

	async function updateBringerData() {
		setButtonLoading(true);
		setButtonDisabled(true);
		let formdata = new FormData();
		let exportedFileContent;

		if (imageUri) {
			try {
				exportedFileContent = await FileSystem.readAsStringAsync(imageUri, {
					encoding: FileSystem.EncodingType.Base64,
				});
			} catch (error) {
				console.log(error);

				return;
			}

			let uri = imageUri;
			let fileExtension = uri.substr(uri.lastIndexOf('.') + 1);

			let img_to_upload = {
				type: 'image/' + fileExtension,
				name: 'license-' + userInfo.id + '.' + fileExtension,
				uri: uri,
			};

			formdata.append('license', img_to_upload);
		} else {
			let uri = userInfo.extended.driverlicenseUrl;
			let fileExtension = uri.substr(uri.lastIndexOf('.') + 1);

			let img_to_upload = {
				type: 'image/' + fileExtension,
				name: 'license-' + userInfo.id + '.' + fileExtension,
				uri: uri,
			};

			formdata.append('license', img_to_upload);
		}

		formdata.append('licenseplate', licenseplate);
		formdata.append('art', selectedCar.name);
		formdata.append('towhook', towhook ? 1 : 0);
		formdata.append('roofrack', roofrack ? 1 : 0);
		formdata.append('bikerack', bikerack ? 1 : 0);
		formdata.append('volume', parseInt(volume));

		try {
			let res = await axios.post(
				process.env.APIURL + 'user/validate',
				//'https://webhook.site/9b27fa5e-5ab2-48b4-909e-ec8d070491d6',
				formdata,
				{
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
					transformRequest: (formdata) => formdata,
				}
			);

			setButtonDisabled(false);
			setButtonLoading(false);

			let testForPersistedUser = localStorage.getItem('ubring_login');

			if (testForPersistedUser) {
				 localStorage.setItem(
						'ubring_login',
						JSON.stringify({
							accessToken: data.accessToken,
							user: data.user,
						})
					);
			}

			navigation.goBack();

			dispatch(setUserInformation(res.data.data));
		} catch (error) {
			if (error.message) {
				console.log(error.response.data);
				Alert.alert(
					'Fehler',
					error.response?.data.errors[0] || 'Es ist ein Fehler aufgetreten'
				);
			}

			setButtonDisabled(false);
			setButtonLoading(false);
		}
	}

	return (
		<KeyboardAwareScrollView
			enableAutomaticScroll
			enableOnAndroid
			nestedScrollEnabled={true}
			keyboardShouldPersistTaps="handled"
		>
			<TouchableWithoutFeedback
				onPress={() => {
					if (Platform.OS == 'web') return;
					Keyboard.dismiss();
				}}
				style={tw.style('flex-1')}
			>
				<SafeAreaView
					edges={['left', 'right']}
					style={tw.style('flex-1 px-6 py-12 items-center')}
				>
					<View style={tw.style('w-full')}>
						<BottomSheet modalProps={{}} isVisible={isVisible}>
							{list.map((l, i) => (
								<ListItem
									key={i}
									containerStyle={l.containerStyle}
									onPress={() => {
										setVolume(l.volume);
										setIsVisible(false);
										setSelectedCar(l);
									}}
									bottomDivider
								>
									<View style={tw.style('flex flex-row')}>
										<ListItem.Content style={tw.style('flex-1')}>
											<ListItem.Title style={tw.style('')}>
												{l.name}
											</ListItem.Title>
											<ListItem.Subtitle>{l.description}</ListItem.Subtitle>
										</ListItem.Content>
										<Image
											source={l.icon_url ? {uri: l.icon_url} : getIcon(l.icon)}
											style={[tw.style('h-10 w-15'), {transform: [{scale: 2}]}]}
											resizeMode="contain"
										/>
									</View>
								</ListItem>
							))}
							<TouchableWithoutFeedback onPress={() => setIsVisible(false)}>
								<View
									style={tw.style(
										'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
									)}
								>
									<MaterialIcons name="close" size={18} />
									<Text style={tw.style('ml-3')}>Schließen</Text>
								</View>
							</TouchableWithoutFeedback>
						</BottomSheet>
						<Text style={tw.style('font-semibold text-gray-600 mb-4')}>
							Führerschein
						</Text>
						<View
							style={tw.style(
								'bg-gray-300 rounded-md w-full flex items-center mb-8'
							)}
							onLayout={(event) => {
								var {x, y, width, height} = event.nativeEvent.layout;
								setImageViewWidth(width);
							}}
						>
							{imageUri ? (
								<Image
									source={{uri: imageUri}}
									style={tw.style(
										'w-full h-[' + imageViewSize + 'px] max-h-1/1 rounded-xl'
									)}
									resizeMethod="scale"
									resizeMode="contain"
								/>
							) : (
								<Image
									source={{uri: userInfo.extended.driverlicenseUrl}}
									style={tw.style('w-full h-40')}
									resizeMethod="scale"
									resizeMode="contain"
								/>
							)}
						</View>
						<View style={tw.style('flex flex-row justify-evenly my-6 ')}>
							{Platform.OS != 'web' && (
								<TouchableOpacity onPress={() => handleUploadImage('camera')}>
									<View style={tw.style('flex justify-center items-center')}>
										<View
											style={tw.style(
												'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
											)}
										>
											<Image
												source={Camera}
												style={tw.style('w-8 h-8')}
												resizeMode="contain"
											/>
										</View>
										<Text style={tw.style('my-2 font-light')}>Aufnehmen</Text>
									</View>
								</TouchableOpacity>
							)}

							<TouchableOpacity onPress={() => handleUploadImage('libary')}>
								<View style={tw.style('flex justify-center items-center')}>
									<View
										style={tw.style(
											'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
										)}
									>
										<Image
											source={Placeholder}
											style={tw.style('w-8 h-8')}
											resizeMode="contain"
										/>
									</View>
									<Text style={tw.style('my-2 font-light')}>Auswählen</Text>
								</View>
							</TouchableOpacity>
							{imageUri && (
								<TouchableOpacity
									onPress={() => {
										setImageName(null);
										setImageUri(null);
										setImageType(null);
										setImageViewHeight(0);
									}}
								>
									<View style={tw.style('flex justify-center items-center')}>
										<View
											style={tw.style(
												'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
											)}
										>
											<Image
												source={Trash}
												style={tw.style('w-8 h-8')}
												resizeMode="contain"
											/>
										</View>
										<Text style={tw.style('my-2 font-light')}>Löschen</Text>
									</View>
								</TouchableOpacity>
							)}
						</View>

						<Text style={tw.style('font-semibold text-gray-600 mb-4')}>
							Fahrzeugtyp
						</Text>
						<TouchableOpacity
							style={tw.style(
								'bg-white rounded-lg shadow-md p-4 mb-8 overflow-hidden flex items-center flex flex-row justify-between'
							)}
							onPress={() => setIsVisible(true)}
						>
							<View style={tw.style('w-1/2 pr-2')}>
								<Text style={tw.style('text-base')}>{selectedCar.name}</Text>
								<Text style={tw.style('text-sm font-light')} numberOfLines={1}>
									{selectedCar.description}
								</Text>
							</View>
							<View style={tw.style('flex flex-row items-center')}>
								<Image
									style={tw.style('h-12 w-30 mx-2')}
									source={selectedCar.icon_url ? {uri: selectedCar.icon_url} : getIcon(selectedCar.icon)}
								/>
								<MaterialIcons name="unfold-more" size={28} />
							</View>
						</TouchableOpacity>
						<Text style={tw.style('font-semibold text-gray-600')}>
							Kennzeichen
						</Text>
						<View style={tw.style('flex flex-row items-center mt-4')}>
							<Input
								placeholder={
									userInfo.extended?.licenseplate || 'Fahrzeugkennzeichen'
								}
								value={licenseplate}
								onChangeText={(text) => setLicensePlate(text)}
							/>
						</View>
					</View>
					<View style={tw.style('w-full ')}>
						<Text style={tw.style('font-semibold text-gray-600')}>
							Kofferraumvolumen
						</Text>
						<View
							style={tw.style(
								'flex flex-row justify-between items-center mt-4'
							)}
						>
							<Text style={tw.style('mb-3 mr-4')}>Volumen in Liter</Text>
							<Input
								value={volume.toString()}
								onChangeText={(text) => {
									setVolume(text);
								}}
								placeholder="0,0"
								width={32}
								textCenter
								textColor="gray-600 font-bold"
								keyboardType="numeric"
								disabled
							/>
						</View>
					</View>
					{selectedCar && (
						<>
							<Text
								style={tw.style('font-semibold text-gray-600 mb-4 w-full mt-8')}
							>
								Fahrzeugzubehör
							</Text>
							{selectedCar.vehicle_accessories.includes('towhook') && (
								<TouchableWithoutFeedback
									style={tw.style('w-full')}
									onPress={() => setTowHook(!towhook)}
								>
									<View
										style={tw.style(
											'w-full flex-row justify-between items-center'
										)}
									>
										<Text>Anhängerkupplung</Text>
										<CheckBox
											checked={towhook}
											onPress={() => setTowHook(!towhook)}
											containerStyle={tw.style(
												'bg-background shadow-none border-0 bg-opacity-0'
											)}
											checkedIcon={
												<MaterialIcons
													name="check-box"
													color={tw.color('primary')}
													size={24}
												/>
											}
											uncheckedIcon={
												<MaterialIcons
													name="check-box-outline-blank"
													color={tw.color('primary')}
													size={24}
												/>
											}
										/>
									</View>
								</TouchableWithoutFeedback>
							)}
							{selectedCar.vehicle_accessories.includes('roofrack') && (
								<TouchableWithoutFeedback
									style={tw.style('w-full')}
									onPress={() => {
										setRoofRack(!roofrack);
									}}
								>
									<View
										style={tw.style(
											'w-full flex-row justify-between items-center'
										)}
									>
										<Text>Dachgepäckträger</Text>
										<CheckBox
											checked={roofrack}
											onPress={() => {
												setRoofRack(!roofrack);
											}}
											containerStyle={tw.style(
												'bg-background shadow-none border-0 bg-opacity-0'
											)}
											checkedIcon={
												<MaterialIcons
													name="check-box"
													color={tw.color('primary')}
													size={24}
												/>
											}
											uncheckedIcon={
												<MaterialIcons
													name="check-box-outline-blank"
													color={tw.color('primary')}
													size={24}
												/>
											}
										/>
									</View>
								</TouchableWithoutFeedback>
							)}
							{selectedCar.vehicle_accessories.includes('bikerack') && (
								<TouchableWithoutFeedback
									style={tw.style('w-full')}
									onPress={() => {
										setBikeRack(!bikerack);
									}}
								>
									<View
										style={tw.style(
											'w-full flex-row justify-between items-center'
										)}
									>
										<Text>Fahrradträger</Text>
										<CheckBox
											checked={bikerack}
											onPress={() => {
												setBikeRack(!bikerack);
											}}
											containerStyle={tw.style(
												'bg-background shadow-none border-0 bg-opacity-0'
											)}
											checkedIcon={
												<MaterialIcons
													name="check-box"
													color={tw.color('primary')}
													size={24}
												/>
											}
											uncheckedIcon={
												<MaterialIcons
													name="check-box-outline-blank"
													color={tw.color('primary')}
													size={24}
												/>
											}
										/>
									</View>
								</TouchableWithoutFeedback>
							)}
						</>
					)}
				</SafeAreaView>
			</TouchableWithoutFeedback>
			<View style={tw.style('flex flex-row mx-6 mb-4')}>
				<BackButton onPress={() => navigation.goBack()} />
				<NextButton
					label="Speichern"
					showIcon={buttonLoading}
					loading={buttonLoading}
					disabled={buttonDisabled || buttonLoading}
					onPress={() => updateBringerData()}
				/>
			</View>
		</KeyboardAwareScrollView>
	);
}
