import {View, Text, TouchableOpacity, ScrollView} from 'react-native';
import React, {useState} from 'react';
import tw from '../../../lib/tailwind';
import {useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';
import dateFormat from 'dateformat';
import {Icon} from 'react-native-elements';
import {useNavigation} from '@react-navigation/native';
import {MaterialIcons} from '@expo/vector-icons';

export default function ViewRequests() {
	const token = useSelector(selectToken);

	const navigation = useNavigation();

	const [requests, setRequests] = useState([]);

	useEffect(() => {
		getRequests();
	}, []);

	async function getRequests() {
		try {
			let res = await axios.get(process.env.APIURL + 'shipment/request', {
				headers: {'Authorization': `Bearer ${token}`},
			});

			if (res.data) {
				setRequests(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function deleteRequest(id) {
		console.log(id);

		try {
			let res = await axios.delete(process.env.APIURL + 'shipment/request', {
				data: {id: id},
				headers: {'Authorization': `Bearer ${token}`},
			});

			getRequests();
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<ScrollView contentContainerStyle={tw.style('p-6')}>
			<View style={tw.style('flex flex-row justify-between mb-6')}>
				<BackButton onPress={() => navigation.goBack()} />
				<Text style={tw.style('text-xl font-bold text-center w-2/4')}>
					Meine Anfragen
				</Text>
				<View style={tw.style('w-1/4')} />
			</View>
			{requests.map((request) => {
				return (
					<View
						style={tw.style(
							'bg-white rounded-lg p-4 mb-4 flex flex-row justify-between items-center'
						)}
					>
						<View>
							<Text style={tw.style('text-lg font-bold')}>{request.name}</Text>
							<Text style={tw.style('text-base font-thin')}>
								{dateFormat(new Date().setTime(request.date), 'dd.mm.yyyy')}
							</Text>
						</View>
						<TouchableOpacity
							onPress={() => {
								deleteRequest(request.id);
							}}
						>
							<MaterialIcons
								name="delete"
								type="material"
								color={tw.color('gray-600')}
								size={18}
							/>
						</TouchableOpacity>
					</View>
				);
			})}
		</ScrollView>
	);
}
