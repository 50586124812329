import React, { useEffect, useState } from 'react'
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';
import BackButton from '../../../uiKit/backButton';
import { useNavigation } from '@react-navigation/native';
import tw from '../../../lib/tailwind';

import * as Location from 'expo-location';
import * as ImagePicker from 'expo-image-picker';
import * as Notifications from 'expo-notifications';

import * as Linking from 'expo-linking';


export default function ViewPermissions() {
    const navigation = useNavigation();

    const [locationPermissions, setLocationPermissions] = useState(null);
    const [cameraPermissions, setCameraPermissions] = useState(null);
    const [libraryPermissions, setLibraryPermissions] = useState(null);
    const [notificationPermissions, setNotificationPermissions] = useState(null);

    const [deepLinkingPermissions, setDeepLinkingPermissions] = useState(null);

    useEffect(() => {
        fetchPermissions();
    }, [])

    async function fetchPermissions() {
        try {
            const statusLocation = await Location.requestForegroundPermissionsAsync();
            setLocationPermissions(statusLocation.status);

            const statusCamera = await ImagePicker.getCameraPermissionsAsync();
            setCameraPermissions(statusCamera.status);

            const statusLibrary = await ImagePicker.getMediaLibraryPermissionsAsync();
            setLibraryPermissions(statusLibrary.status);

            const statusNotification = await Notifications.requestPermissionsAsync();
            setNotificationPermissions(statusNotification.status);
            
            setDeepLinkingPermissions(await Linking.canOpenURL("ubring://"));

        } catch (error) {
            console.log(error);
        }
    }
    

  return (
    <ScrollView contentContainerStyle={tw.style('p-6')}>
        <View style={tw.style('flex flex-row justify-between mb-6')}>
            <BackButton onPress={() => navigation.goBack()} />
            <Text style={tw.style('text-xl font-bold text-center w-2/4')}>
                Berechtigungen
            </Text>
            <View style={tw.style('w-1/4')} />
        </View>

        <View style={tw.style('flex justify-between flex-row')}>
            <Text style={tw.style('text-lg font-bold')}>
                Standort
            </Text>
            <Text style={tw.style('text-base')}>
                {locationPermissions === 'granted' ? 'Zugelassen' : 'Nicht zugelassen'}
            </Text>
        </View>
        <View style={tw.style('flex justify-between flex-row')}>
            <Text style={tw.style('text-lg font-bold')}>
                Kamera
            </Text>
            <Text style={tw.style('text-base')}>
                {cameraPermissions === 'granted' ? 'Zugelassen' : 'Nicht zugelassen'}
            </Text>
        </View>
        <View style={tw.style('flex justify-between flex-row')}>
            <Text style={tw.style('text-lg font-bold')}>
                Galerie
            </Text>
            <Text style={tw.style('text-base')}>
                {libraryPermissions === 'granted' ? 'Zugelassen' : 'Nicht zugelassen'}
            </Text>
        </View>
        <View style={tw.style('flex justify-between flex-row')}>
            <Text style={tw.style('text-lg font-bold')}>
                Benachrichtigungen
            </Text>
            <Text style={tw.style('text-base')}>
                {notificationPermissions === 'granted' ? 'Zugelassen' : 'Nicht zugelassen'}
            </Text>
        </View>
        <View style={tw.style('flex justify-between flex-row flex-wrap')}>
            <Text style={tw.style('text-lg font-bold')}>
                Deep Linking
            </Text>
            <Text style={tw.style('text-base')}>
                {deepLinkingPermissions ? 'Links zu app.u-bring.de werden direkt in der u-bring-App geöffnet.' : 'Links zu app.u-bring.de werden nicht in der u-bring-App geöffnet.'}
            </Text>
        </View>

        <View style={tw.style('flex justify-between flex-row flex-wrap mt-12 items-center')}>
            <Text style={tw.style('text-lg font-bold')}>
                Zu den App-Einstellungen
            </Text>
            <TouchableOpacity onPress={() => Linking.openSettings()}>
                <Text style={tw.style('text-base underline')}>
                    Hier klicken
                </Text>
            </TouchableOpacity>
        </View>
        <View style={tw.style('flex justify-between flex-row flex-wrap mt-12 items-center')}>
            <Text style={tw.style('text-base')}>
            Falls du noch Fragen hast, schau doch gerne in unsere FAQ vorbei.
            </Text>
            <TouchableOpacity onPress={() => Linking.openURL('https://u-bring.de/faq')}>
                <Text style={tw.style('text-base underline')}>
                    Zu den FAQ
                </Text>
            </TouchableOpacity>
        </View>
    </ScrollView>
  )
}
