import * as Location from 'expo-location';

import MarkerBringer from '../../../assets/images/icons/map/Bringer_MapIcon.png';
import MarkerSender from '../../../assets/images/icons/map/Sender_MapIcon.png';
import MarkerFinish from '../../../assets/images/icons/map/Finish_MapIcon.png';

import MapViewDirections from 'react-native-maps-directions';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';
import tw from '../../../lib/tailwind';
import {
	Alert,
	AppState,
	Dimensions,
	Image,
	Linking,
	Platform,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import NextButton from '../../../uiKit/nextButton';
import BackButton from '../../../uiKit/backButton';
import axios from 'axios';
import MapView from 'react-native-maps';

import {useIsFocused} from '@react-navigation/native';

import * as Browser from 'expo-web-browser';
import * as IntentLauncher from 'expo-intent-launcher';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';

export default function LiveTracking({navigation, route}) {
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [loading, setLoading] = useState(false);

	const [buttonText, setButtonText] = useState('Abgeben');
	const [bringerLocation, setBringerLocation] = useState(null);

	const [ownLocation, setOwnLocation] = useState(null);

	const [isReady, setIsReady] = useState(false);

	const isFocused = useIsFocused();

	const token = useSelector(selectToken);

	const params = route.params;

	const originAddress = params.data.originAddress;

	const destinationAddress = params.data.destinationAddress;

	const data = params.data;

	const userInfo = useSelector(selectUserInfo);

	const mapRef = useRef();

	const [initialFit, setInitialFit] = useState(false);

	var requests = data.requests;

	let request = null;

	const [routeData, setRouteData] = useState(null);

	const [acceptedRequestData, setAcceptedRequestData] = useState({});

	const [role, setRole] = useState('');

	useLayoutEffect(() => {
		var tempData = requests.find(
			(filterItem) => data.data.accepted_request_id === filterItem.id
		);

		if (!tempData) {
			navigation.replace('waitingForBringer');
			return;
		}

		setAcceptedRequestData(tempData);
		request = tempData;

		getOwnLocation();
	}, []);
	useEffect(() => {
		navigation.addListener('focus', () => {
			getShipments();
		});

		let shipmentInterval = setInterval(() => {
			getShipments();
		}, 3000);

		let sub = AppState.addEventListener('change', () => {
			getShipments();
		});

		return () => {
			navigation.removeListener('focus');
			sub.remove();
			clearInterval(shipmentInterval);
		};
	}, [navigation]);

	useEffect(() => {
		getShipments();
	}, [isFocused]);

	function getShipments() {
		axios
			.get(process.env.APIURL + 'shipment/' + data.data.id, {
				headers: {'Authorization': `Bearer ${token}`},
			})
			.then((res) => {
				//console.log(res);
				if (res.data) {
					const item = res.data.data;

					var requests = item.requests;

					let acceptedRequestData = requests.find(
						(filterItem) => item.data.accepted_request_id === filterItem.id
					);
					console.log(acceptedRequestData);

					setAcceptedRequestData(acceptedRequestData);
					request = acceptedRequestData;
				}
			})
			.catch((e) => console.log(e));
	}

	async function getOwnLocation() {
		let location = await Location.getLastKnownPositionAsync();

		if (location) {
			setOwnLocation(location);
		}

		let currentLocation = await Location.getCurrentPositionAsync({
			accuracy: Location.Accuracy.Highest,
			mayShowUserSettingsDialog: true,
		});

		if (currentLocation) {
			setOwnLocation(currentLocation);
		}
	}

	useEffect(() => {
		if (isReady && !initialFit) {
			setInitialFit(true);
			if (Platform.OS != 'web') {
				mapRef.current.fitToSuppliedMarkers(
					['origin', 'destination', 'bringer'],
					{
						edgePadding: {
							top: 75,
							left: 75,
							bottom: (Dimensions.get('window').height / 3) * 3 + 75,
							right: 75,
						},
					}
				);
			}
		}
	}, [isReady, bringerLocation]);

	useEffect(() => {
		// Ablauf
		// 1. Bringer muss Paket annehmen
		// 2. Sender muss Paket abgeben
		// 3. Empfänger muss Paket annehmen
		// 4. Bringer muss Paket abgeben
		// 5. Wenn Bringer Paket wieder abgegeben hat, dann muss er die Bezahlung bestätigen

		if (!acceptedRequestData) return;
		// Sender
		if (data.data.userId == userInfo.id) {
			setRole('sender');
			if (
				acceptedRequestData?.bringer_recieved_from_sender == 1 &&
				acceptedRequestData?.sender_handed_over == 0
			) {
				setButtonDisabled(false);
				setButtonText('Paket übergeben');
			} else {
				if (acceptedRequestData?.bringer_recieved_from_sender == 0) {
					setButtonDisabled(false);
					setButtonText('Auftrag abbrechen');
				} else if (acceptedRequestData?.bringer_handed_over == 1) {
					setButtonDisabled(true);
					setButtonText('Auftrag abgeschlossen');
				} else {
					setButtonDisabled(true);
					setButtonText('Bringer bewerten');
					testIfAlreadyRated();
				}
			}
		}

		// Bringer
		if (acceptedRequestData?.bringer_id == userInfo.id) {
			setRole('bringer');
			if (acceptedRequestData?.bringer_recieved_from_sender == 0) {
				setButtonDisabled(false);
				setButtonText('Paket annehmen');
			} else if (
				acceptedRequestData?.recipient_recieved_from_bringer == 1 &&
				acceptedRequestData?.bringer_handed_over == 0
			) {
				setButtonDisabled(false);
				setButtonText('Paket abgeben');
			} else if (
				acceptedRequestData.recipient_recieved_from_bringer == 1 &&
				acceptedRequestData.bringer_handed_over == 1 &&
				acceptedRequestData.sender_payed != 1
			) {
				setButtonDisabled(false);
				setButtonText('Auftrag abschließen');
			} else if (acceptedRequestData.sender_payed == 1) {
				setButtonDisabled(true);
				setButtonText('Auftrag abgeschlossen');
			} else {
				setButtonDisabled(true);
				setButtonText('In Zustellung');
			}
		}

		// Empfänger
		if (data.data.recipient.toLowerCase() == userInfo.email) {
			setRole('recipient');

			if (
				parseInt(acceptedRequestData.recipient_recieved_from_bringer) == 0 &&
				parseInt(acceptedRequestData.bringer_recieved_from_sender) == 1 &&
				parseInt(acceptedRequestData.sender_handed_over) == 1
			) {
			} else if (acceptedRequestData?.recipient_recieved_from_bringer == 1) {
				setButtonDisabled(true);
				setButtonText('Bringer bewerten');
				testIfAlreadyRated();
			} else if (
				acceptedRequestData?.sender_handed_over == 0 &&
				acceptedRequestData?.bringer_recieved_from_sender == 0
			) {
				setButtonDisabled(true);
				setButtonText('Paket angekündigt');
			} else {
				setButtonDisabled(true);
				setButtonText('In Zustellung');
			}
		}
	}, [data, acceptedRequestData]);

	useEffect(() => {
		handleLiveTracking();

		let interval = setInterval(() => {
			handleLiveTracking();
		}, 3000);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, []);

	async function testIfAlreadyRated() {
		if (acceptedRequestData.sender_handed_over != 1) {
			return;
		}

		try {
			let res = await axios.patch(
				process.env.APIURL + 'user/rating/',
				{
					recipientID: acceptedRequestData.bringer_id,
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			);

			let ratings = res.data.ratings;

			let rating = ratings.find((rating) => {
				return (
					rating.shipmentID == acceptedRequestData.shipment_id &&
					rating.userID == userInfo.id
				);
			});

			if (rating) {
				if (data.data.userId == userInfo.id) {
					setButtonDisabled(true);
					setButtonText('In Zustellung');
				} else {
					if (acceptedRequestData.bringer_handed_over == 1) {
						setButtonDisabled(true);
						setButtonText('Auftrag abgeschlossen');
					} else {
						setButtonDisabled(true);
						setButtonText('In Zustellung');
					}
				}
				return;
			} else {
				setButtonDisabled(false);
				setButtonText('Bringer bewerten');
			}
		} catch (error) {
			console.warn(
				error.response.data,
				process.env.APIURL + 'user/rating/' + acceptedRequestData.bringer_id
			);
			setButtonDisabled(false);
			setButtonText('Bringer bewerten');
		}
	}

	async function handleLiveTracking() {
		try {
			var tempData = request;

			if (parseInt(acceptedRequestData.recipient_recieved_from_bringer) == 1) {
				return;
			}

			if (tempData.bringer_id == userInfo.id) {
				let location = await Location.getCurrentPositionAsync({
					accuracy: Location.Accuracy.Highest,
				});

				if (!location.coords) {
					return;
				}

				setBringerLocation(location.coords);

				try {
					let res = await axios.post(
						process.env.APIURL + 'shipment/livetracking',
						{
							requestID: parseInt(data.data.accepted_request_id),
							longitude: location.coords.longitude,
							latitude: location.coords.latitude,
						},
						{
							headers: {'Authorization': `Bearer ${token}`},
						}
					);
				} catch (error) {
					console.log(error.response);
				}
			} else {
				try {
					const res = await axios.put(
						process.env.APIURL + 'shipment/livetracking',
						{
							requestID: parseInt(data.data.accepted_request_id),
						},
						{
							headers: {'Authorization': `Bearer ${token}`},
						}
					);

					if (res.data) {
						setBringerLocation(res.data);

						console.log(request);

						if (
							request?.sender_handed_over == 1 &&
							request?.recipient_recieved_from_bringer == 0 &&
							data.data.recipient.toLowerCase() == userInfo.email
						) {
							let location = await Location.getCurrentPositionAsync({
								accuracy: Location.Accuracy.Highest,
							});

							let distanceToDestination = getDistance(
								{
									latitude: parseFloat(res.data.latitude),
									longitude: parseFloat(res.data.longitude),
								},

								{
									latitude: parseFloat(destinationAddress.latitude),
									longitude: parseFloat(destinationAddress.longitude),
								}
							);

							let distanceToMe = getDistance(
								{
									latitude: parseFloat(res.data.latitude),
									longitude: parseFloat(res.data.longitude),
								},
								{
									latitude: parseFloat(location?.coords.latitude),
									longitude: parseFloat(location?.coords.longitude),
								}
							);

							if (
								parseInt(distanceToDestination) < 300 &&
								parseInt(distanceToMe) < 300
							) {
								setButtonDisabled(false);
								setButtonText('Paket annehmen');
							} else {
								setButtonDisabled(true);
								setButtonText('In Zustellung');
							}
						}
					}
				} catch (error) {
					console.log(error);
				}
			}
		} catch (error) {
			console.log(error.response);
		}
	}

	async function deleteRequest() {
		try {
			let res = await axios.delete(process.env.APIURL + 'shipment/request', {
				data: {id: data.data.accepted_request_id},
				headers: {'Authorization': `Bearer ${token}`},
			});

			navigation.goBack();
			Alert.alert('Erfolgreich', 'Die Fahrt wurde erfolgreich abgebrochen.');
		} catch (error) {
			Alert.alert(
				'Fehler',
				error.response.data?.message ||
					'Beim Löschen der Anfrage ist ein Fehler aufgetreten.',

				[
					{
						text: 'OK',
						onPress: () => navigation.navigate('main', {merge: true}),
					},
				]
			);
			console.log(error.response);
		}
	}

	function handleNextButton() {
		if (buttonText === 'Auftrag abbrechen') {
			Alert.alert(
				'Abbrechen',
				'Bist du dir sicher das du diese Fahrt abbrechen möchtest?',
				[
					{
						text: 'Fahrt abbrechen',
						style: 'destructive',
						onPress: () => deleteRequest(),
					},
					{text: 'Zurück', style: 'cancel'},
				],
				{cancelable: true}
			);
		} else if (buttonText == 'Bringer bewerten') {
			navigation.navigate('rating', {
				ratingRecipientID: acceptedRequestData?.bringer_id,
				shipmentID: acceptedRequestData.shipment_id,
			});
		} else if (buttonText == 'Auftrag abschließen') {
			navigation.push('confirmHandOver', {
				requestID: data.data.accepted_request_id,
				action: 'bringerPayed',
				type: 'sender_payed',
				senderID: parseInt(data.data.userId),
				recipientEmail: data.data.recipient.toLowerCase(),
				shipmentID: parseInt(data.data.id),
			});
		} else {
			if (
				parseInt(acceptedRequestData.bringer_handed_over) === 1 &&
				parseInt(acceptedRequestData.recipient_recieved_from_bringer) === 1
			) {
				Alert.alert(
					'Fehler',
					'Das Paket wurde bereits an den Empfänger übergeben!'
				);
			} else {
				var type = '';
				var action = '';

				if (data.data.recipient.toLowerCase() === userInfo.email) {
					if (!bringerLocation) {
						Alert.alert(
							'Fehler',
							'Wir konnten den Standort des Bringers nicht ermitteln!'
						);
						return;
					}

					let distanceToDestination = getDistance(
						{
							latitude: parseFloat(bringerLocation.latitude),
							longitude: parseFloat(bringerLocation.longitude),
						},

						{
							latitude: parseFloat(destinationAddress.latitude),
							longitude: parseFloat(destinationAddress.longitude),
						}
					);

					let distanceToMe = getDistance(
						{
							latitude: parseFloat(bringerLocation.latitude),
							longitude: parseFloat(bringerLocation.longitude),
						},
						{
							latitude: parseFloat(ownLocation.coords.latitude),
							longitude: parseFloat(ownLocation.coords.longitude),
						}
					);

					if (
						parseInt(distanceToDestination) > 300 ||
						parseInt(distanceToMe) > 300
					) {
						Alert.alert(
							'Fehler',
							'Der Bringer ist nicht in deiner Nähe und des Ziels!'
						);
						return;
					}

					type = 'recipient_recieved_from_bringer';
					action = 'recieve';
				} else if (
					data.data.recipient.toLowerCase() !== userInfo.email &&
					parseInt(data.data.userId) !== userInfo.id
				) {
					if (parseInt(acceptedRequestData.bringer_recieved_from_sender)) {
						type = 'bringer_handed_over';
						action = 'handOver';
					} else {
						type = 'bringer_recieved_from_sender';
						action = 'recieve';
					}
				} else if (userInfo.id == parseInt(data.data.userId)) {
					type = 'sender_handed_over';
					action = 'handOver';
				}

				navigation.push('confirmHandOver', {
					requestID: data.data.accepted_request_id,
					action,
					type,
					shipmentID: parseInt(data.data.shipment_id),
				});
			}
		}
	}

	function getDistance(origin, destination) {
		const lon1 = toRadian(origin.longitude);
		const lat1 = toRadian(origin.latitude);
		const lon2 = toRadian(destination.longitude);
		const lat2 = toRadian(destination.latitude);

		const deltaLat = lat2 - lat1;
		const deltaLon = lon2 - lon1;

		const a =
			Math.pow(Math.sin(deltaLat / 2), 2) +
			Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
		const c = 2 * Math.asin(Math.sqrt(a));
		const EARTH_RADIUS = 6371;
		return c * EARTH_RADIUS * 1000; // convert to meters
	}

	function toRadian(degree) {
		return (degree * Math.PI) / 180;
	}

	return (
		<View style={tw.style('flex-1')}>
			<MapView
				initialRegion={{
					latitude: 51.37052,
					longitude: 10.52277,
					latitudeDelta: 5,
					longitudeDelta: 5,
				}}
				loadingIndicatorColor={tw.color('primary')}
				loadingBackgroundColor={tw.color('gray-300')}
				mapType="standard"
				style={tw.style('w-full flex-1')}
				ref={mapRef}
				onMapReady={() => {
					setIsReady(true);
				}}
			>
				{/**  TODO: Route von Start Route zu Start Paket und Route von Ende Paket zu Ende Route  */}
				{acceptedRequestData?.bringer_id == userInfo.id &&
					acceptedRequestData.destinationAddress &&
					acceptedRequestData.originAddress && (
						<>
							<MapViewDirections
								origin={{
									latitude: parseFloat(
										acceptedRequestData.originAddress.latitude
									),
									longitude: parseFloat(
										acceptedRequestData.originAddress.longitude
									),
								}}
								destination={{
									latitude: parseFloat(originAddress.latitude),
									longitude: parseFloat(originAddress.longitude),
								}}
								apikey={process.env.GOOGLE_API_KEY}
								strokeColor={tw.color('primary')}
								strokeWidth={2}
								mode="DRIVING"
							/>
							<MapViewDirections
								origin={{
									latitude: parseFloat(destinationAddress.latitude),
									longitude: parseFloat(destinationAddress.longitude),
								}}
								destination={{
									latitude: parseFloat(
										acceptedRequestData.destinationAddress.latitude
									),
									longitude: parseFloat(
										acceptedRequestData.destinationAddress.longitude
									),
								}}
								apikey={process.env.GOOGLE_API_KEY}
								strokeColor={tw.color('primary')}
								strokeWidth={2}
								mode="DRIVING"
							/>
						</>
					)}

				<MapViewDirections
					origin={{
						latitude: parseFloat(originAddress.latitude),
						longitude: parseFloat(originAddress.longitude),
					}}
					destination={{
						latitude: parseFloat(destinationAddress.latitude),
						longitude: parseFloat(destinationAddress.longitude),
					}}
					apikey={process.env.GOOGLE_API_KEY}
					strokeColor={tw.color('accent')}
					strokeWidth={2}
					mode="DRIVING"
				/>
				{bringerLocation && (
					<MapViewDirections
						origin={{
							latitude: parseFloat(bringerLocation.latitude),
							longitude: parseFloat(bringerLocation.longitude),
						}}
						destination={{
							latitude: parseFloat(destinationAddress.latitude),
							longitude: parseFloat(destinationAddress.longitude),
						}}
						apikey={process.env.GOOGLE_API_KEY}
						strokeColor={tw.color('transparent')}
						strokeWidth={2}
						mode="DRIVING"
						onReady={(result) => {
							delete result.coordinates;

							setRouteData(result);
						}}
					/>
				)}
					<>
						<MapView.Marker
							identifier="origin"
							coordinate={{
								latitude: parseFloat(originAddress.latitude),
								longitude: parseFloat(originAddress.longitude),
							}}
							//icon={Platform.OS === 'web' ? MarkerSender : null}
							tracksViewChanges={false}
							style={{zIndex: 10}}
							image={MarkerSender}
						>
							{/* <Image source={MarkerSender} resizeMode="contain" /> */}
						</MapView.Marker>
						<MapView.Marker
							identifier="destination"
							coordinate={{
								latitude: parseFloat(destinationAddress.latitude),
								longitude: parseFloat(destinationAddress.longitude),
							}}
							//icon={Platform.OS === 'web' ? MarkerFinish : null}
							tracksViewChanges={false}
							style={{zIndex: 10}}
							image={MarkerFinish}
						>
							{/* <Image source={MarkerFinish} resizeMode="contain" /> */}
						</MapView.Marker>
						{bringerLocation && (
							<MapView.Marker
								identifier="bringer"
								coordinate={{
									latitude: parseFloat(bringerLocation.latitude),
									longitude: parseFloat(bringerLocation.longitude),
								}}
								//icon={Platform.OS === 'web' ? MarkerBringer : null}
								tracksViewChanges={false}
								style={{zIndex: 10}}
								image={MarkerBringer}
							>
								{/* <Image source={MarkerBringer} resizeMode="contain" /> */}
							</MapView.Marker>
						)}
					</>
				
			</MapView>
			<View
				style={tw.style(
					'bg-white rounded-t-3xl absolute left-5 right-5 bottom-0 shadow-lg flex py-5 px-5'
				)}
			>
				<View style={tw.style('flex flex-row')}>
					{/*<View style={tw.style('w-1/6')}></View>*/}
					<View style={tw.style('w-6/6 px-2')}>
						<View style={tw.style('mb-3')}>
							<Text style={tw.style('text-accent text-lg')}>Start</Text>
							<View
								style={tw.style(
									'flex flex-row justify-between pb-1 border-b border-gray-100'
								)}
							>
								<Text style={tw.style('font-light')}>Adresse:</Text>
								<Text style={tw.style('max-w-1/2 text-right font-light')}>
									{originAddress.longString}
								</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between py-1 border-b border-gray-100'
								)}
							>
								<Text style={tw.style('font-light')}>Absender:</Text>
								<Text style={tw.style('max-w-1/2 font-light')}>
									{data.sender.first_name + ' ' + data.sender.last_name}
								</Text>
							</View>
							<View style={tw.style('flex flex-row justify-between pt-1')}>
								<Text style={tw.style('font-light')}>Paket:</Text>
								<Text style={tw.style('max-w-1/2 font-light')}>
									{data.data.name + ' (' + data.category?.name + ')'}
								</Text>
							</View>
						</View>
						<View>
							<View style={tw.style('flex flex-row ')}>
								<Text style={tw.style('text-primary text-lg  ')}>Ziel</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between pb-1 border-b border-gray-100'
								)}
							>
								<Text style={tw.style('font-light')}>Adresse:</Text>
								<Text style={tw.style('max-w-1/2 text-right font-light')}>
									{destinationAddress.longString}
								</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between py-1 border-b border-gray-100'
								)}
							>
								<Text style={tw.style('font-light')}>Ankunft:</Text>
								<Text style={tw.style('max-w-1/2 font-light')}>
									{routeData
										? routeData.legs[0].duration.text
												.replace('days', 'Tage')
												.replace('day', 'Tag')
												.replace('hours', 'Stunden')
												.replace('hour', 'Stunde')
												.replace('mins', 'Minuten')
												.replace('min', 'Minute')
										: 'noch nicht unterwegs'}
								</Text>
							</View>
						</View>
						<TouchableOpacity
							style={tw.style('mt-2')}
							onPress={() => {
								// Browser.openBrowserAsync(
								// 	process.env.APIURL + 'shipmentDoc/TV-' + data.data.id + '.pdf'
								// );

								if (Platform.OS == 'web') {
									const aElement = document.createElement('a');
									const href =
										process.env.APIURL +
										'shipmentDoc/TV-' +
										data.data.id +
										'.pdf';
									aElement.href = href;
									aElement.download = 'TV-' + data.data.id + '.pdf';
									document.body.appendChild(aElement);
									aElement.click();
									document.body.removeChild(aElement);
									return;
								}

								FileSystem.downloadAsync(
									process.env.APIURL +
										'shipmentDoc/TV-' +
										data.data.id +
										'.pdf',
									FileSystem.documentDirectory + 'TV-' + data.data.id + '.pdf'
								)
									.then(({uri}) => {
										FileSystem.getContentUriAsync(uri).then((cUri) => {
											if (Platform.OS == 'ios') {
												Sharing.shareAsync(cUri);
											} else {
												IntentLauncher.startActivityAsync(
													'android.intent.action.VIEW',
													{
														data: cUri,
														flags: 1,
														type: 'application/pdf',
													}
												);
											}
										});
									})
									.catch((error) => {
										console.error(error);
									});
							}}
						>
							<Text style={tw.style('text-center text-base underline')}>
								Transportvertrag ansehen
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<View style={tw.style('flex flex-row pt-6')}>
					<BackButton
						onPress={() => {
							navigation.replace('authenticatedContainer', {
								screen: 'uMission',
								merge: true,
							});
						}}
					/>
					<NextButton
						onPress={() => handleNextButton()}
						label={buttonText}
						disabled={true}
						showIcon={loading}
						loading={loading}
					/>
				</View>
			</View>
		</View>
	);
}
