import {
	View,
	Text,
	TextInput,
	KeyboardAvoidingView,
	TouchableOpacity,
	ScrollView,
	Image,
	TouchableWithoutFeedback,
	Platform,
	Alert,
	Share,
} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import tw from '../../../lib/tailwind';
import Input from '../../../uiKit/input';
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import {BottomSheet, Icon, ListItem} from 'react-native-elements';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import Placeholder from '../../../assets/images/icons/image_placeholder.png';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import dateFormat from 'dateformat';

import {v4} from 'uuid';
import * as ImagePicker from 'expo-image-picker';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MaterialIcons} from '@expo/vector-icons';

export default function CreatePackage({navigation, route}) {
	const token = useSelector(selectToken);

	const [name, setName] = useState('');
	const [category, setCategory] = useState({
		name: 'Bitte wählen',
		id: null,
	});
	const [price, setPrice] = useState('');

	const priceSheetRef = useRef(null);

	const userInfo = useSelector((state) => state.user.userInformation);

	const [packageVolume, setPackageVolume] = useState('');
	const [packageHeight, setPackageHeight] = useState('');
	const [packageWidth, setPackageWidth] = useState('');
	const [packageLength, setPackageLength] = useState('');
	const [manualSelect, setManualSelect] = useState(false);

	const [originInformationError, setOriginInformationError] = useState(false);
	const [destinationInformationError, setDestinationInformationError] =
		useState(false);
	const [packageLengthError, setPackageLengthError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);
	const [priceError, setPriceError] = useState(false);

	const [date, setDate] = useState(
		new Date(new Date().setDate(new Date().getDate() + 2))
	); // 2 days from now

	const [buttonDeactivated, setButtonDeactivated] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);

	const [imgWidth, setImgWidth] = useState(0);

	const [imageToUpload, setImageToUpload] = useState(null);

	const [originInformation, setOriginInformation] = useState({
		sender: {gender: '', firstname: '', lastname: ''},
		location: {
			postal: '',
			street: '',
			number: '',
			city: '',
			state: '',
			latitude: 0.0,
			longitude: 0.0,
			shortString: '',
			longString: '',
			placeID: null,
		},
		isInnitSet: true,
	});
	const [destinationInformation, setDestinationInformation] = useState({
		reciever: {gender: '', firstname: '', lastname: '', email: ''},
		location: {
			postal: '',
			street: '',
			number: '',
			city: '',
			state: '',
			latitude: 0.0,
			longitude: 0.0,
			shortString: '',
			longString: '',
			placeID: null,
		},
		isInnitSet: true,
	});

	const titleStyle = tw.style('font-semibold text-lg mb-2');

	useEffect(() => {
		if (route.params?.sender) {
			setOriginInformation(route.params?.sender);
		}
	}, [route.params?.sender]);

	useEffect(() => {
		if (route.params?.destination) {
			setDestinationInformation(route.params?.destination);
		}
	}, [route.params?.destination]);

	function verifyInput() {
		if (name.trim() === '') {
			setNameError('Bitte gib einen Namen ein');
			return;
		} else {
			setNameError(false);
		}

		if (category.id == 'customListID') {
			setCategoryError('Bitte wähle eine Kategorie aus');
			return;
		} else {
			setCategoryError(false);
		}

		if (
			packageLength.trim() == '' ||
			packageHeight.trim() == '' ||
			packageWidth.trim() == ''
		) {
			setPackageLengthError('Bitte gib alle Maße an.');
			return;
		} else {
			setPackageLengthError(false);
		}

		if (parseFloat(price.replace(',', '.')) * 100 === 0) {
			setPriceError('Bitte gib einen Preis an');
			return;
		} else {
			setPriceError(false);
		}

		if (destinationInformation.reciever.email.trim() === userInfo.email) {
			setDestinationInformationError(
				'Du kannst dir selbst kein Paket schicken'
			);
			return;
		} else {
			setDestinationInformationError(false);
		}

		if (originInformation.isInnitSet) {
			setOriginInformationError('Bitte gib einen Absender an');
			return;
		} else {
			setOriginInformationError(false);
		}

		if (destinationInformation.isInnitSet) {
			setDestinationInformationError('Bitte gib einen Empfänger an');
			return;
		} else {
			setDestinationInformationError(false);
		}

		createPackage();
	}

	useEffect(() => {
		axios
			.get(
				process.env.APIURL + 'getCategorys',

				{headers: {'Authorization': `Bearer ${token}`}}
			)
			.then((res) => {
				if (res.data) {
					//console.log(res.data);
					setList(res.data);
					setCategory({
						createdAt: '2022-04-22 14:31:57',
						description: 'Bitte warten...',
						id: 'customListID',
						modifiedAt: '2022-04-22 14:31:57',
						name: 'Bitte wählen',
						price: '0',
						width: 9999,
						height: 9999,
						length: 9999,
					});
				}
			})
			.catch((error) => {
				setButtonLoading(false);
				if (error.response) {
					console.log(error.response.data);
					Alert.alert(
						'Fehler',
						error.response.data.message || 'Ein Fehler ist aufgetreten'
					);
				}
			});

		axios
			.get(
				process.env.APIURL + 'getDays',

				{headers: {'Authorization': `Bearer ${token}`}}
			)
			.then((res) => {
				if (res.data) {
					console.log('Days: ' + res.data);

					const result = new Date();
					result.setDate(result.getDate() + parseInt(res.data));

					setDate(result);
					console.log('Date: ' + result);
				}
			})
			.catch((error) => {
				setButtonLoading(false);
				if (error.response) {
					console.log(error.response.data);
					Alert.alert(
						'Fehler',
						error.response.data.message || 'Ein Fehler ist aufgetreten'
					);
				}
			});
	}, []);

	useEffect(() => {
		var tempLength = packageLength !== '' ? parseFloat(packageLength) : 0.0;
		var tempHeight = packageHeight !== '' ? parseFloat(packageHeight) : 0.0;
		var tempWidth = packageWidth !== '' ? parseFloat(packageWidth) : 0.0;

		setPackageVolume(
			((tempLength * tempHeight * tempWidth) / 1000000).toFixed(3)
		);
	}, [packageLength, packageHeight, packageWidth]);

	function createPackage() {
		setButtonLoading(true);

		let formdata = new FormData();

		formdata.append('name', name);
		formdata.append('category', parseInt(category.id));
		formdata.append('length', parseInt(packageLength));
		formdata.append('width', parseInt(packageWidth));
		formdata.append('height', parseInt(packageHeight));
		formdata.append('recipient', destinationInformation.reciever.email.trim());
		formdata.append('origin', JSON.stringify(originInformation.location));
		formdata.append(
			'destination',
			JSON.stringify(destinationInformation.location)
		);
		formdata.append(
			'price',
			Math.round(parseFloat(price.replace(',', '.')) * 100)
		);
		formdata.append('date', new Date(date).getTime());

		formdata.append('shipment', imageToUpload);

		axios
			.post(process.env.APIURL + 'shipment', formdata, {
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-Type': 'multipart/form-data',
				},
				transformRequest: (formdata) => formdata,
			})
			.then((res) => {
				if (res.data) {
					setButtonLoading(false);
					navigation.replace('CompletedPackage');
				}
			})
			.catch((error) => {
				setButtonLoading(false);
				if (error.response) {
					console.log(error.response.data);

					if (error.response.data.error_code == 'recipientNotRegistered') {
						Alert.alert(
							'Fehler',
							'Der Empfänger ist nicht bei uns registriert. Du kannst ihn allerdings gerne einladen.',
							[
								{
									text: 'Ok',
									style: 'cancel',
								},
								{
									text: 'Einladen',
									onPress: () => {
										Share.share({
											title: 'Einladung zu U-BRING',
											message:
												'Hallo ' +
												destinationInformation?.reciever?.firstname +
												',\n ich möchte Dir ein Paket mit U-BRING senden.\n Bitte lade Dir die kostenlose U-BRING APP herunter und melde Dich an, damit ich Dir das Paket schicken kann.\n https://u-bring.de',
											subject: 'Einladung zu U-BRING',
										});
									},
								},
							]
						);
					} else {
						Alert.alert(
							'Fehler',
							error.response.data.message || 'Ein Fehler ist aufgetreten'
						);
					}
				}
			});
	}

	const [list, setList] = useState([
		{
			createdAt: '2022-04-22 14:31:57',
			description: 'Bitte warten...',
			id: 'customListID',
			modifiedAt: '2022-04-22 14:31:57',
			name: 'Wird geladen....',
			price: '0',
			width: 9999,
			height: 9999,
			length: 9999,
		},
	]);

	useEffect(() => {
		if (!disableDimensions && !manualSelect) {
			let tempList = list;

			var tempLength = packageLength !== '' ? parseFloat(packageLength) : 0.0;
			var tempHeight = packageHeight !== '' ? parseFloat(packageHeight) : 0.0;
			var tempWidth = packageWidth !== '' ? parseFloat(packageWidth) : 0.0;

			if (tempLength > 9999) {
				tempLength = 9999;
			}

			if (tempHeight > 9999) {
				tempHeight = 9999;
			}
			if (tempWidth > 9999) {
				tempWidth = 9999;
			}

			if (tempLength == 0 && tempHeight == 0 && tempWidth == 0) {
				tempLength = 9999;
				tempHeight = 9999;
				tempWidth = 9999;
			}

			tempList = tempList.filter((i) => {
				if (
					!(
						i.length < tempLength ||
						i.width < tempWidth ||
						i.height < tempHeight
					)
				) {
					return i;
				}
			});

			setCategory(tempList[0]);
			setPrice(
				(parseInt(tempList[0].price) / 100).toFixed(2).replace('.', ',')
			);
		}
	}, [packageHeight, packageWidth, packageVolume]);

	const [disableDimensions, setDisableDimensions] = useState(false);

	const [openCategorie, setOpenCategorie] = useState(false);

	const [openModal, setOpenModal] = useState(false);

	async function takePackageImage(type) {
		let result;
		try {
			if (type === 'camera') {
				let permRes = await ImagePicker.getCameraPermissionsAsync();

				if (permRes.granted === false) {
					permRes = await ImagePicker.requestCameraPermissionsAsync();

					if (permRes.granted === false) {
						Alert.alert(
							'Fehlende Berechtigung',
							'Bitte erlaube der App den Zugriff auf die Kamera'
						);
						return;
					}
				}

				result = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [1, 1],
					quality: 0,
				});
			} else {
				let permRes = await ImagePicker.getMediaLibraryPermissionsAsync();
				if (permRes.granted === false) {
					permRes = await ImagePicker.requestMediaLibraryPermissionsAsync();
					if (permRes.granted === false) {
						Alert.alert(
							'Fehlende Berechtigung',
							'Bitte erlaube der App den Zugriff auf die Medien'
						);
						return;
					}
				}
				result = await ImagePicker.launchImageLibraryAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [1, 1],
					quality: 0,
				});
			}
		} catch (error) {
			console.log(error);
		}

		if (!result.canceled) {
			let uri = result.assets[0].uri;
			let fileExtension = uri.substr(uri.lastIndexOf('.') + 1);

			let img_to_upload = {
				type: 'image/' + fileExtension,
				name: 'package-' + v4() + '.' + fileExtension,
				uri: result.assets[0].uri,
			};

			setImageToUpload(img_to_upload);
		}
	}

	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<BottomSheet modalProps={{}} isVisible={openModal}>
				<TouchableWithoutFeedback
					onPress={() => {
						takePackageImage('libary');
						setOpenModal(false);
					}}
				>
					<View
						style={tw.style(
							'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
						)}
					>
						<MaterialIcons name="collections" size={18} />
						<Text style={tw.style('ml-3')}>Bild auswählen</Text>
					</View>
				</TouchableWithoutFeedback>
				{Platform.OS != 'web' && (
					<TouchableWithoutFeedback
						onPress={() => {
							takePackageImage('camera');
							setOpenModal(false);
						}}
					>
						<View
							style={tw.style(
								'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
							)}
						>
							<MaterialIcons name="camera" size={18} />
							<Text style={tw.style('ml-3')}>Bild aufnehmen</Text>
						</View>
					</TouchableWithoutFeedback>
				)}
				<TouchableWithoutFeedback onPress={() => setOpenModal(false)}>
					<View
						style={tw.style(
							'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
						)}
					>
						<MaterialIcons name="close" size={18} />
						<Text style={tw.style('ml-3')}>Schließen</Text>
					</View>
				</TouchableWithoutFeedback>
			</BottomSheet>
			<BottomSheet modalProps={{}} isVisible={openCategorie}>
				{list.map((l, i) => {
					let tempprice = l.price / 100;

					let formatedPrice = tempprice.toFixed(2).replace('.', ',') + ' €';

					var tempLength =
						packageLength !== '' ? parseFloat(packageLength) : 0.0;
					var tempHeight =
						packageHeight !== '' ? parseFloat(packageHeight) : 0.0;
					var tempWidth = packageWidth !== '' ? parseFloat(packageWidth) : 0.0;

					if (tempLength > 9999) {
						tempLength = 9999;
					}

					if (tempHeight > 9999) {
						tempHeight = 9999;
					}
					if (tempWidth > 9999) {
						tempWidth = 9999;
					}

					let itemFilterStyleString = '';

					return (
						<ListItem
							key={i}
							containerStyle={[
								l.containerStyle,
								tw.style(itemFilterStyleString),
							]}
							onPress={() => {
								setManualSelect(true);

								if (
									packageLength === '' ||
									packageLength === '9999' ||
									packageLength === '0' ||
									manualSelect
								) {
									setPackageLength(l.length);
								}

								if (
									packageWidth === '' ||
									packageWidth === '9999' ||
									packageWidth === '0' ||
									manualSelect
								) {
									setPackageWidth(l.width);
								}

								if (
									packageHeight === '' ||
									packageHeight === '9999' ||
									packageHeight === '0' ||
									manualSelect
								) {
									setPackageHeight(l.height);
								}

								if (
									(l.height === '9999' &&
									l.width === '9999' &&
									l.length === '9999') ||
									(l.height === '0' &&
									l.width === '0' &&
									l.length === '0')
								) {
									setPackageWidth(l.width);
									setPackageLength(l.length);
									setPackageHeight(l.height);
									setDisableDimensions(true);
								} else {
									setDisableDimensions(false);
								}

								setCategory(l);
								setPrice(formatedPrice.replace(' €', ''));
								setOpenCategorie(false);
							}}
							bottomDivider
						>
							<ListItem.Content>
								<ListItem.Title style={l.titleStyle}>{l.name}</ListItem.Title>
								<ListItem.Subtitle>{l.description}</ListItem.Subtitle>
							</ListItem.Content>
						</ListItem>
					);
				})}
				<TouchableWithoutFeedback onPress={() => setOpenCategorie(false)}>
					<View
						style={tw.style(
							'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
						)}
					>
						<MaterialIcons name="close" size={18} />
						<Text style={tw.style('ml-3')}>Schließen</Text>
					</View>
				</TouchableWithoutFeedback>
			</BottomSheet>

			<KeyboardAwareScrollView
				style={tw.style('')}
				contentContainerStyle={tw.style('flex justify-between')}
			>
				<View style={tw.style('w-full')}>
					<View style={tw.style('px-6 pt-6')}>
						<Text style={titleStyle}>Was wird versandt?</Text>
						<Input
							placeholder="Gib hier Name oder Bezeichnung ein"
							value={name}
							onChangeText={(event) => setName(event)}
							error={nameError}
						/>
					</View>
					<View style={tw.style(' bg-gray-300 bg-opacity-30 py-3 px-6')}>
						<View style={tw.style('flex flex-row w-full items-center')}>
							<View
								style={tw.style(
									Platform.OS == 'web' ? 'w-1/3 h-full' : 'w-1/3 flex-1 '
								)}
							>
								<TouchableOpacity
									style={tw.style(
										Platform.OS == 'web'
											? 'flex-1 w-full h-full flex items-center justify-center'
											: 'flex-1 flex items-center justify-center'
									)}
									onPress={() => setOpenModal(true)}
								>
									<Image
										style={tw.style(
											Platform.OS == 'web'
												? 'bg-gray-500 rounded-lg w-full h-full flex items-center justify-center'
												: 'bg-gray-500 rounded-lg w-1/1 h-1/1 flex items-center justify-center'
										)}
										onLayout={(event) => {
											let width = event.nativeEvent.layout.width;
											setImgWidth(width);
										}}
										source={
											imageToUpload ? {uri: imageToUpload.uri} : Placeholder
										}
										resizeMode="cover"
									/>
								</TouchableOpacity>
							</View>

							<View style={tw.style('w-2/3 pl-3')}>
								<Text style={titleStyle}>Kategorie</Text>
								<TouchableWithoutFeedback
									onPress={() => setOpenCategorie(true)}
								>
									<View
										style={tw.style(
											'flex flex-row items-center justify-between bg-white rounded-xl shadow-md mb-4 py-4 px-3'
										)}
									>
										<Text>{category.name}</Text>
										<MaterialIcons name="unfold-more" size={28} />
									</View>
								</TouchableWithoutFeedback>
								{categoryError && (
									<Text style={tw.style('text-red-500 mt-1')}>
										{categoryError}
									</Text>
								)}
							</View>
						</View>
						{!disableDimensions && (
							<View>
								<View style={tw.style('flex flex-row pt-6')}>
									<View style={tw.style('w-1/3 px-1')}>
										<Input
											keyboardType="numeric"
											placeholder="cm"
											value={packageLength}
											onChangeText={(event) => {
												setManualSelect(false);
												setPackageLength(event);
											}}
											preText={'L:'}
										/>
									</View>
									<View style={tw.style('w-1/3 px-1')}>
										<Input
											keyboardType="numeric"
											placeholder="cm"
											value={packageWidth}
											onChangeText={(event) => {
												setManualSelect(false);
												setPackageWidth(event);
											}}
											preText={'B:'}
										/>
									</View>
									<View style={tw.style('w-1/3 px-1')}>
										<Input
											keyboardType="numeric"
											placeholder="cm"
											value={packageHeight}
											onChangeText={(event) => {
												setManualSelect(false);
												setPackageHeight(event);
											}}
											preText={'H:'}
										/>
									</View>
								</View>
								{packageLengthError && (
									<Text style={tw.style('text-red-500 mt-1')}>
										{packageLengthError}
									</Text>
								)}
							</View>
						)}
					</View>
					<View style={tw.style('px-6 py-3')}>
						<Text style={titleStyle}>
							Bis wann soll das Paket abgeholt werden?
						</Text>
						<DateTimePickerModal
							isVisible={showModal}
							onConfirm={(date) => {
								setDate(date);
								setShowModal(false);
							}}
							onCancel={() => {
								setShowModal(false);
							}}
							isDarkModeEnabled={false}
							textColor={tw.color('black')}
							confirmTextIOS="Bestätigen"
							cancelTextIOS="Abbrechen"
							date={typeof date.getT ? date : new Date()}
							themeVariant="light"
							minimumDate={new Date()}
							locale="de-DE"
							maximumDate={
								new Date(new Date().setFullYear(new Date().getFullYear() + 1))
							}
						/>
						<TouchableOpacity onPress={() => setShowModal(true)}>
							<View
								style={tw.style(
									'flex flex-row items-center bg-white rounded-xl shadow-md mb-4 w-full' +
										(Platform.OS == 'web' ? ' px-4 py-3' : '')
								)}
							>
								{Platform.OS == 'web' ? (
									<input
										type="date"
										value={dateFormat(date, 'yyyy-mm-dd')}
										onChange={(event) => {
											setDate(new Date(event.target.value));
										}}
										min={dateFormat(new Date(), 'yyyy-mm-dd')}
										style={{
											all: 'unset',
											flex: 1,
											height: '100%',
										}}
									/>
								) : (
									<TextInput
										style={tw.style('flex-1 h-full py-4 px-3 text-black')}
										value={dateFormat(date, 'dd.mm.yyyy')}
										editable={false}
										onPressIn={() => setShowModal(true)}
									/>
								)}
							</View>
						</TouchableOpacity>
					</View>
					<View style={tw.style('px-6 py-3')}>
						<View>
							<Text style={titleStyle}>Adresse von</Text>
							{originInformation.isInnitSet ? (
								<TouchableOpacity
									style={tw.style(
										'p-4 border border-accent rounded-lg flex flex-row justify-center items-center bg-white'
									)}
									onPress={() => navigation.push('setOrigin')}
								>
									<Text style={tw.style('mr-1 font-semibold')}>
										Sender eintragen
									</Text>
									<MaterialIcons
										name="add"
										color={tw.color('accent')}
										size={18}
									/>
								</TouchableOpacity>
							) : (
								<TouchableOpacity
									onPress={() =>
										navigation.push('setOrigin', originInformation)
									}
								>
									<View>
										<Text>
											{(
												originInformation.sender.gender +
												' ' +
												originInformation.sender.firstname +
												' ' +
												originInformation.sender.lastname
											).trim()}
										</Text>
										<Text>
											{originInformation.location.street +
												' ' +
												originInformation.location.number}
										</Text>
										<Text>
											{originInformation.location.postal +
												' ' +
												originInformation.location.city}
										</Text>
										<Text>{originInformation.location.state}</Text>
									</View>
								</TouchableOpacity>
							)}
							{originInformationError && (
								<Text style={tw.style('text-red-500 mt-1')}>
									{originInformationError}
								</Text>
							)}
						</View>
					</View>

					<View style={tw.style('px-6 py-3')}>
						<Text style={titleStyle}>Adresse nach</Text>
						{destinationInformation.isInnitSet ? (
							<TouchableOpacity
								style={tw.style(
									'p-4 border border-accent rounded-lg flex flex-row justify-center items-center bg-white'
								)}
								onPress={() => navigation.push('setDestination')}
							>
								<Text style={tw.style('mr-1 font-semibold')}>
									Empfänger eintragen
								</Text>
								<MaterialIcons
									name="add"
									color={tw.color('accent')}
									size={18}
								/>
							</TouchableOpacity>
						) : (
							<TouchableOpacity
								onPress={() =>
									navigation.push('setDestination', destinationInformation)
								}
							>
								<View>
									<Text>
										{(
											destinationInformation.reciever.gender +
											' ' +
											destinationInformation.reciever.firstname +
											' ' +
											destinationInformation.reciever.lastname
										).trim()}
									</Text>
									<Text>
										{destinationInformation.location.street +
											' ' +
											destinationInformation.location.number}
									</Text>
									<Text>
										{destinationInformation.location.postal +
											' ' +
											destinationInformation.location.city}
									</Text>
									<Text>{destinationInformation.location.state}</Text>
								</View>
							</TouchableOpacity>
						)}
						{destinationInformationError && (
							<Text style={tw.style('text-red-500 mt-1')}>
								{destinationInformationError}
							</Text>
						)}
					</View>
					<View
						style={tw.style(
							'flex flex-row justify-between items-center mx-6 my-2'
						)}
					>
						<View>
							<Text style={tw.style('text-lg font-semibold ')}>
								Transportprämie
							</Text>
							<Text style={tw.style('text-sm text-gray-400')}>
								(zzgl. Vermittlung)
							</Text>
							<Text style={tw.style('text-sm text-gray-400')}>
								Der Preis ist anpassbar
							</Text>
						</View>

						<View
							style={tw.style(
								'bg-white rounded-lg shadow-md z-60 flex flex-row items-center pr-4'
							)}
						>
							<TextInput
								style={tw.style('pl-4 py-3 pr-2')}
								placeholder="0,00"
								placeholderTextColor={tw.color('black')}
								value={price}
								onChangeText={setPrice}
								keyboardType="numeric"
							/>
							<Text>€</Text>
						</View>
					</View>
					{priceError && (
						<Text style={tw.style('text-red-500 mt-1 mb-3 px-6')}>
							{priceError}
						</Text>
					)}
				</View>
				<View
					style={tw.style('w-full flex flex-row justify-between px-6 mb-6')}
				>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => verifyInput()}
						label="Paket einstellen"
						loading={buttonLoading}
						showIcon={buttonLoading}
					/>
				</View>
				{Platform.OS === 'ios' && (
					<KeyboardSpacer onToggle={(open, space) => {}} />
				)}
			</KeyboardAwareScrollView>
		</>
	);
}
