import {
	View,
	Text,
	FlatList,
	Dimensions,
	Animated,
	ScrollView,
	TouchableOpacity,
	Alert,
} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import BackButton from '../../../uiKit/backButton';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import axios from 'axios';
import tw from '../../../lib/tailwind';
import PackageCard from '../../../uiKit/packageCard';
import NextButton from '../../../uiKit/nextButton';
import {Avatar, Icon} from 'react-native-elements';
import dateFormat from 'dateformat';
import LoadingScreen from '../../../uiKit/loadingScreen';
import {Image} from 'react-native';
import Ratings from '../../../uiKit/Ratings';
import {MaterialIcons} from '@expo/vector-icons';

import multiavatar from '@multiavatar/multiavatar'
import { SvgXml } from 'react-native-svg';

export default function PackageDetail({navigation, route}) {
	const packageID = route.params.selectedPackage;

	const token = useSelector(selectToken);

	const [packageData, setPackageData] = useState({});

	const [formatedPrice, setFormatedPrice] = useState('');

	const [loading, setLoading] = useState(true);
	const [startTime, setStartTime] = useState();

	const [checkLegal, setCheckLegal] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.get(process.env.APIURL + 'shipment/' + packageID, {
				headers: {'Authorization': `Bearer ${token}`},
			})
			.then((res) => {
				if (res.data) {
					setPackageData(res.data.data);
					setLoading(false);
				}
			})
			.catch((e) => console.log(e.response));
	}, [token]);

	useEffect(() => {
		if (!loading) {
			let tempprice = packageData.data.price / 100;

			setFormatedPrice(tempprice.toFixed(2).replace('.', ',') + ' €');
		}
	}, [packageData, loading]);

	if (loading) {
		return <LoadingScreen />;
	}

	async function deletePackage() {
		try {
			const res = await axios.delete(
				process.env.APIURL + 'shipment/' + parseInt(packageID),

				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			);

			if (res.data) {
				//console.log(res.data);

				navigation.replace('authenticatedContainer', {
					screen: 'uMission',
					merge: true,
				});
			}
		} catch (error) {
			console.log(error);

			Alert.alert('Fehler', 'Beim Löschen ist ein Fehler aufgetreten');
		}
	}

	async function extendDate() {
		try {
			const res = await axios.put(
				process.env.APIURL + 'shipment',
				{
					id: parseInt(packageID),
					date: parseInt(packageData.data.date) + 172800000, // 2 days
				},

				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			);

			if (res.data) {
				navigation.replace('authenticatedContainer', {
					screen: 'uMission',
					merge: true,
				});
			}
		} catch (error) {
			console.log(error.response);

			Alert.alert('Fehler', 'Beim Verlängern ist ein Fehler aufgetreten');
		}
	}

	const avatar = multiavatar(packageData?.sender?.user_email);

	return (
		<ScrollView contentContainerStyle={tw.style('p-6 flex justify-between')}>
			<View style={tw.style('w-full mb-8 flex flex-row')}>
				{packageData.sender?.avatarSrc !== "" ? <Avatar
					rounded
					size={84}
					source={{
						uri:
							packageData.sender?.avatarSrc
					}}
				/>:
					<div style={{width: 84, height:84}} dangerouslySetInnerHTML={{__html: avatar}} />
				}
				<View style={tw.style('ml-4 flex flex-col')}>
					<Text style={tw.style('text-accent text-base')}>U-Send</Text>
					<Text style={tw.style('text-black text-2xl')}>
						{packageData.sender?.first_name} {packageData.sender?.last_name}
					</Text>
					<Ratings userID={packageData.data.userId} />
				</View>
			</View>
			<View
				style={tw.style(
					'bg-white flex mb-8 flex-row rounded-xl shadow-md w-full overflow-hidden'
				)}
			>
				<Image
					source={{
						uri:
							packageData.data.imageSrc != ''
								? packageData.data.imageSrc
								: 'https://images.unsplash.com/photo-1577705998148-6da4f3963bc8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
					}}
					style={[
						tw.style('min-h-[150px] h-full w-1/3 rounded-lg'),
						{aspectRatio: 1},
					]}
				/>
				<View style={tw.style('w-2/3 p-3')}>
					<View style={tw.style('flex flex-row justify-between')}>
						<Text style={tw.style('font-bold text-base')}>Preis:</Text>
						<Text style={tw.style('font-bold text-accent text-3xl')}>
							{formatedPrice}
						</Text>
					</View>
					<View
						style={tw.style(
							'flex flex-row justify-between py-3 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('font-bold text-base')}>
							Paket:
							{' "' +
								packageData.data.name +
								'" (' +
								packageData.category?.name +
								')'}
						</Text>
					</View>
					<View
						style={tw.style('flex flex-row justify-between py-3 flex-wrap')}
					>
						<Text style={tw.style('text-base')}>Größe:</Text>
						{packageData.data.length != 9999 && (
							<Text style={tw.style('font-bold text-accent')}>
								<Text style={tw.style('font-bold text-base')}>
									{'L: ' +
										packageData.data.length +
										'cm / B: ' +
										packageData.data.width +
										'cm / H: ' +
										packageData.data.height +
										'cm'}
								</Text>
							</Text>
						)}
					</View>
				</View>
			</View>
			<View style={tw.style('mb-8')}>
				<View>
					<View style={tw.style('pb-1 border-b border-gray-200')}>
						<Text style={tw.style('text-accent text-xl')}>Start</Text>
					</View>
					<View
						style={tw.style(
							'flex flex-row justify-between py-.5 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('text-base font-light')}>Adresse</Text>
						<Text style={tw.style('text-base font-light max-w-2/3 text-right')}>
							{packageData.originAddress?.longString}
						</Text>
					</View>
					<View
						style={tw.style(
							'flex flex-row justify-between py-.5 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('text-base font-light')}>Abfahrt</Text>
						<Text style={tw.style('text-base font-light')}>
							{dateFormat(
								new Date().setTime(packageData.data.date),
								'dd.mm.yyyy'
							)}
						</Text>
					</View>
					<View style={tw.style('pb-1 pt-4 border-b border-gray-200')}>
						<Text style={tw.style('text-primary text-xl')}>Ziel</Text>
					</View>
					<View
						style={tw.style(
							'flex flex-row justify-between py-.5 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('text-base font-light')}>Empfänger</Text>
						<Text style={tw.style('text-base font-light max-w-2/3 text-right')}>
							{packageData.data.recipient}
						</Text>
					</View>
					<View
						style={tw.style(
							'flex flex-row justify-between py-.5 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('text-base font-light')}>Adresse</Text>
						<Text style={tw.style('text-base font-light max-w-2/3 text-right')}>
							{packageData.destinationAddress?.longString}
						</Text>
					</View>
					{/*<View
						style={tw.style(
							'flex flex-row justify-between py-.5 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('text-base font-light')}>Ankunft</Text>
						<Text style={tw.style('text-base font-light')}>
							Wird berechnet...
						</Text>
					</View>*/}
				</View>
			</View>

			<View style={tw.style('flex flex-row justify-between mb-12')}>
				<BackButton onPress={() => navigation.goBack()} />
				<NextButton
					onPress={() => navigation.navigate('ViewBringer', {packageData})}
					label={packageData.requests.length + ' verfügbare(-r) Bringer'}
					disabled={packageData.requests.length < 1}
				/>
			</View>
			<View>
				<TouchableOpacity style={tw.style('mb-8')} onPress={() => extendDate()}>
					<View
						style={tw.style('ml-4 flex flex-row justify-center items-center')}
					>
						<MaterialIcons name="update" size={24} color="#FFAE42" />
						<Text style={tw.style('text-lg ml-3 font-semibold text-[#FFAE42]')}>
							Verlängern um 2 Tage
						</Text>
					</View>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => {
						Alert.alert(
							'Löschen',
							'Bist du dir sicher das du dieses Paket löschen möchtest?',
							[
								{
									text: 'Löschen',
									style: 'destructive',
									onPress: () => deletePackage(),
								},
								{text: 'Abbrechen', style: 'cancel'},
							],
							{cancelable: true}
						);
					}}
				>
					<View
						style={tw.style('ml-4 flex flex-row justify-center items-center')}
					>
						<MaterialIcons name="delete" size={24} color="#ff0000" />
						<Text style={tw.style('text-lg ml-3 font-semibold text-red-500')}>
							Paket löschen
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		</ScrollView>
	);
}
